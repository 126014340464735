import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withGetScreen } from 'react-getscreen'
import NavMainDesktop from './NavMainDesktop'
import NavMainMobile from './NavMainMobile'

class NavMain extends Component {
    render() {
        if (this.props.isMobile()) {
          return <NavMainMobile />;
        } else {
          return <NavMainDesktop />;
        }
      }
}

const options = { mobileLimit: 900, shouldListenOnResize: true }
export default connect()(withGetScreen(NavMain, options))

