import React, { Component } from 'react'
import { connect } from 'react-redux'
import SpecializedMaterialsDesktop from './SpecializedMaterialsDesktop'
import SpecializedMaterialsMobile from './SpecializedMaterialsMobile'
import { withGetScreen } from 'react-getscreen'

class SpecializedMaterials extends Component {
  render() {
    if (this.props.isMobile()) {
      return <SpecializedMaterialsMobile />
    } else {
      return <SpecializedMaterialsDesktop />
    }
  }
}

const options = { mobileLimit: 900, shouldListenOnResize: true }
export default connect()(withGetScreen(SpecializedMaterials, options))
