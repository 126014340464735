import React, { Component } from 'react'
import { connect } from 'react-redux'
import SolutionsListDesktop from './SolutionsListDesktop'
import SolutionsListMobile from './SolutionsListMobile'
import { withGetScreen } from 'react-getscreen'

class SolutionsList extends Component {
  render() {
    if (this.props.isMobile()) {
      return <SolutionsListMobile />;
    } else {
      return <SolutionsListDesktop />;
    }
  }
}

const options = { mobileLimit: 900, shouldListenOnResize: true }
export default connect()(withGetScreen(SolutionsList, options))
