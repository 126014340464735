import React, { Component } from 'react'
import { connect } from 'react-redux'
import FooterMobile from './FooterMobile'

class Footer extends Component {
  render() {
    return (
      <div className='footer'>
        <FooterMobile/>
      </div>
    );
  }
}

export default connect()(Footer);

