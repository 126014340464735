import React, { Component } from 'react'
import { connect } from 'react-redux'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Footer } from '../../components'
import { SpecializedMaterialsItems, SpecializedMaterialsForm } from './'
import './mstyles.scss'

class SpecializedMaterialsMobile extends Component {
    render() {
        return (
            <div className='specialized-materials-m page-bg'>
                <div className='container-m'>
                    <div className='content-m'>
                        <div className='go-to-bttn'>
                            <AnchorLink href='#form' className='bttn'>Вход<span className='icon icon-arrow-right'></span></AnchorLink>
                        </div>
                        <SpecializedMaterialsItems />
                        <div className='form-wrap' id="form">
                            <SpecializedMaterialsForm />
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

        )
    }
}

export default connect()(SpecializedMaterialsMobile)
