export const coefficents = [
    { height: 0, speed: 0, quantity: 0 },
    { height: 0.024, speed: 0.158986985, quantity: 0.001 },
    { height: 0.033, speed: 0.196621052, quantity: 0.002 },
    { height: 0.04, speed: 0.222518442, quantity: 0.003 },
    { height: 0.046, speed: 0.242925312, quantity: 0.004 },
    { height: 0.051, speed: 0.260046303, quantity: 0.005 },
    { height: 0.055, speed: 0.274876635, quantity: 0.006 },
    { height: 0.06, speed: 0.288078317, quantity: 0.007 },
    { height: 0.064, speed: 0.300016007, quantity: 0.008 },
    { height: 0.067, speed: 0.310972125, quantity: 0.009 },
    { height: 0.071, speed: 0.321051519, quantity: 0.010 },
    { height: 0.074, speed: 0.330496174, quantity: 0.011 },
    { height: 0.077, speed: 0.33930035, quantity: 0.012 },
    { height: 0.08, speed: 0.347630259, quantity: 0.013 },
    { height: 0.083, speed: 0.35553187, quantity: 0.014 },
    { height: 0.085, speed: 0.363046464, quantity: 0.015 },
    { height: 0.088, speed: 0.370189755, quantity: 0.016 },
    { height: 0.091, speed: 0.377029335, quantity: 0.017 },
    { height: 0.093, speed: 0.383594423, quantity: 0.018 },
    { height: 0.096, speed: 0.389910518, quantity: 0.019 },
    { height: 0.098, speed: 0.395991114, quantity: 0.020 },
    { height: 0.1, speed: 0.401845973, quantity: 0.021 },
    { height: 0.103, speed: 0.40750806, quantity: 0.022 },
    { height: 0.105, speed: 0.412995435, quantity: 0.023 },
    { height: 0.107, speed: 0.418324439, quantity: 0.024 },
    { height: 0.109, speed: 0.423509869, quantity: 0.025 },
    { height: 0.111, speed: 0.428529676, quantity: 0.026 },
    { height: 0.113, speed: 0.433403897, quantity: 0.027 },
    { height: 0.115, speed: 0.438168984, quantity: 0.028 },
    { height: 0.117, speed: 0.442835605, quantity: 0.029 },
    { height: 0.119, speed: 0.44733823, quantity: 0.030 },
    { height: 0.121, speed: 0.451759525, quantity: 0.031 },
    { height: 0.123, speed: 0.456106386, quantity: 0.032 },
    { height: 0.124, speed: 0.460305899, quantity: 0.033 },
    { height: 0.126, speed: 0.46444651, quantity: 0.034 },
    { height: 0.128, speed: 0.468503149, quantity: 0.035 },
    { height: 0.13, speed: 0.472456014, quantity: 0.036 },
    { height: 0.131, speed: 0.476368045, quantity: 0.037 },
    { height: 0.133, speed: 0.480160641, quantity: 0.038 },
    { height: 0.135, speed: 0.483913463, quantity: 0.039 },
    { height: 0.137, speed: 0.487582931, quantity: 0.040 },
    { height: 0.138, speed: 0.491191794, quantity: 0.041 },
    { height: 0.14, speed: 0.494743619, quantity: 0.042 },
    { height: 0.141, speed: 0.498222431, quantity: 0.043 },
    { height: 0.143, speed: 0.501661792, quantity: 0.044 },
    { height: 0.144, speed: 0.505023238, quantity: 0.045 },
    { height: 0.146, speed: 0.508355032, quantity: 0.046 },
    { height: 0.148, speed: 0.511610686, quantity: 0.047 },
    { height: 0.149, speed: 0.514839541, quantity: 0.048 },
    { height: 0.151, speed: 0.51799996, quantity: 0.049 },
    { height: 0.152, speed: 0.521130258, quantity: 0.050 },
    { height: 0.154, speed: 0.524205077, quantity: 0.051 },
    { height: 0.155, speed: 0.527240965, quantity: 0.052 },
    { height: 0.156, speed: 0.530238971, quantity: 0.053 },
    { height: 0.158, speed: 0.533184379, quantity: 0.054 },
    { height: 0.159, speed: 0.536113591, quantity: 0.055 },
    { height: 0.161, speed: 0.538972244, quantity: 0.056 },
    { height: 0.162, speed: 0.541830897, quantity: 0.057 },
    { height: 0.163, speed: 0.544615407, quantity: 0.058 },
    { height: 0.165, speed: 0.547390838, quantity: 0.059 },
    { height: 0.166, speed: 0.550123889, quantity: 0.060 },
    { height: 0.167, speed: 0.552819452, quantity: 0.061 },
    { height: 0.169, speed: 0.555506956, quantity: 0.062 },
    { height: 0.17, speed: 0.558125834, quantity: 0.063 },
    { height: 0.171, speed: 0.560744712, quantity: 0.064 },
    { height: 0.173, speed: 0.563318392, quantity: 0.065 },
    { height: 0.174, speed: 0.56586361, quantity: 0.066 },
    { height: 0.175, speed: 0.568404899, quantity: 0.067 },
    { height: 0.177, speed: 0.570879333, quantity: 0.068 },
    { height: 0.178, speed: 0.573353767, quantity: 0.069 },
    { height: 0.179, speed: 0.575798929, quantity: 0.070 },
    { height: 0.18, speed: 0.578205314, quantity: 0.071 },
    { height: 0.182, speed: 0.580611698, quantity: 0.072 },
    { height: 0.183, speed: 0.582969855, quantity: 0.073 },
    { height: 0.184, speed: 0.585310792, quantity: 0.074 },
    { height: 0.185, speed: 0.587651729, quantity: 0.075 },
    { height: 0.187, speed: 0.589931263, quantity: 0.076 },
    { height: 0.188, speed: 0.59220923, quantity: 0.077 },
    { height: 0.189, speed: 0.594478453, quantity: 0.078 },
    { height: 0.19, speed: 0.596695809, quantity: 0.079 },
    { height: 0.191, speed: 0.598913164, quantity: 0.080 },
    { height: 0.192, speed: 0.601116295, quantity: 0.081 },
    { height: 0.194, speed: 0.603275288, quantity: 0.082 },
    { height: 0.195, speed: 0.605434281, quantity: 0.083 },
    { height: 0.196, speed: 0.607577922, quantity: 0.084 },
    { height: 0.197, speed: 0.609680696, quantity: 0.085 },
    { height: 0.198, speed: 0.61178347, quantity: 0.086 },
    { height: 0.199, speed: 0.613873692, quantity: 0.087 },
    { height: 0.2, speed: 0.615922291, quantity: 0.088 },
    { height: 0.202, speed: 0.61797089, quantity: 0.089 },
    { height: 0.203, speed: 0.620013264, quantity: 0.090 },
    { height: 0.204, speed: 0.622009639, quantity: 0.091 },
    { height: 0.205, speed: 0.624006015, quantity: 0.092 },
    { height: 0.206, speed: 0.62600239, quantity: 0.093 },
    { height: 0.207, speed: 0.627951673, quantity: 0.094 },
    { height: 0.208, speed: 0.629897686, quantity: 0.095 },
    { height: 0.209, speed: 0.6318437, quantity: 0.096 },
    { height: 0.21, speed: 0.633756728, quantity: 0.097 },
    { height: 0.211, speed: 0.635654158, quantity: 0.098 },
    { height: 0.213, speed: 0.637551589, quantity: 0.099 },
    { height: 0.214, speed: 0.63943259, quantity: 0.100 },
    { height: 0.215, speed: 0.641283137, quantity: 0.101 },
    { height: 0.216, speed: 0.643133684, quantity: 0.102 },
    { height: 0.217, speed: 0.644984231, quantity: 0.103 },
    { height: 0.218, speed: 0.646791822, quantity: 0.104 },
    { height: 0.219, speed: 0.64859711, quantity: 0.105 },
    { height: 0.22, speed: 0.650402397, quantity: 0.106 },
    { height: 0.221, speed: 0.652186937, quantity: 0.107 },
    { height: 0.222, speed: 0.653948519, quantity: 0.108 },
    { height: 0.223, speed: 0.655710101, quantity: 0.109 },
    { height: 0.224, speed: 0.657471682, quantity: 0.110 },
    { height: 0.225, speed: 0.65919413, quantity: 0.111 },
    { height: 0.226, speed: 0.660913492, quantity: 0.112 },
    { height: 0.227, speed: 0.662632853, quantity: 0.113 },
    { height: 0.228, speed: 0.664339754, quantity: 0.114 },
    { height: 0.229, speed: 0.666018317, quantity: 0.115 },
    { height: 0.23, speed: 0.66769688, quantity: 0.116 },
    { height: 0.231, speed: 0.669375443, quantity: 0.117 },
    { height: 0.232, speed: 0.671029949, quantity: 0.118 },
    { height: 0.233, speed: 0.672669076, quantity: 0.119 },
    { height: 0.234, speed: 0.674308202, quantity: 0.120 },
    { height: 0.235, speed: 0.675947328, quantity: 0.121 },
    { height: 0.236, speed: 0.677554403, quantity: 0.122 },
    { height: 0.237, speed: 0.679155396, quantity: 0.123 },
    { height: 0.238, speed: 0.680756388, quantity: 0.124 },
    { height: 0.239, speed: 0.682357381, quantity: 0.125 },
    { height: 0.24, speed: 0.683921612, quantity: 0.126 },
    { height: 0.241, speed: 0.685485721, quantity: 0.127 },
    { height: 0.242, speed: 0.687049829, quantity: 0.128 },
    { height: 0.243, speed: 0.688611139, quantity: 0.129 },
    { height: 0.243, speed: 0.69013956, quantity: 0.130 },
    { height: 0.244, speed: 0.691667982, quantity: 0.131 },
    { height: 0.245, speed: 0.693196403, quantity: 0.132 },
    { height: 0.246, speed: 0.694721867, quantity: 0.133 },
    { height: 0.247, speed: 0.696215749, quantity: 0.134 },
    { height: 0.248, speed: 0.697709631, quantity: 0.135 },
    { height: 0.249, speed: 0.699203513, quantity: 0.136 },
    { height: 0.25, speed: 0.700697394, quantity: 0.137 },
    { height: 0.251, speed: 0.702157229, quantity: 0.138 },
    { height: 0.252, speed: 0.703617672, quantity: 0.139 },
    { height: 0.253, speed: 0.705078116, quantity: 0.140 },
    { height: 0.254, speed: 0.706538559, quantity: 0.141 },
    { height: 0.255, speed: 0.707970631, quantity: 0.142 },
    { height: 0.255, speed: 0.709398692, quantity: 0.143 },
    { height: 0.256, speed: 0.710826753, quantity: 0.144 },
    { height: 0.257, speed: 0.712254813, quantity: 0.145 },
    { height: 0.258, speed: 0.713662191, quantity: 0.146 },
    { height: 0.259, speed: 0.715058883, quantity: 0.147 },
    { height: 0.26, speed: 0.716455574, quantity: 0.148 },
    { height: 0.261, speed: 0.717852266, quantity: 0.149 },
    { height: 0.262, speed: 0.719237775, quantity: 0.150 },
    { height: 0.263, speed: 0.72060407, quantity: 0.151 },
    { height: 0.264, speed: 0.721970366, quantity: 0.152 },
    { height: 0.264, speed: 0.723336661, quantity: 0.153 },
    { height: 0.265, speed: 0.724702957, quantity: 0.154 },
    { height: 0.266, speed: 0.726039735, quantity: 0.155 },
    { height: 0.267, speed: 0.727376569, quantity: 0.156 },
    { height: 0.268, speed: 0.728713403, quantity: 0.157 },
    { height: 0.269, speed: 0.730050237, quantity: 0.158 },
    { height: 0.27, speed: 0.731371037, quantity: 0.159 },
    { height: 0.27, speed: 0.732679307, quantity: 0.160 },
    { height: 0.271, speed: 0.733987577, quantity: 0.161 },
    { height: 0.272, speed: 0.735295847, quantity: 0.162 },
    { height: 0.273, speed: 0.73660283, quantity: 0.163 },
    { height: 0.274, speed: 0.737883399, quantity: 0.164 },
    { height: 0.275, speed: 0.739163968, quantity: 0.165 },
    { height: 0.276, speed: 0.740444537, quantity: 0.166 },
    { height: 0.276, speed: 0.741725106, quantity: 0.167 },
    { height: 0.277, speed: 0.742993386, quantity: 0.168 },
    { height: 0.278, speed: 0.744247083, quantity: 0.169 },
    { height: 0.279, speed: 0.74550078, quantity: 0.170 },
    { height: 0.28, speed: 0.746754477, quantity: 0.171 },
    { height: 0.281, speed: 0.748008174, quantity: 0.172 },
    { height: 0.281, speed: 0.749241162, quantity: 0.173 },
    { height: 0.282, speed: 0.750468784, quantity: 0.174 },
    { height: 0.283, speed: 0.751696407, quantity: 0.175 },
    { height: 0.284, speed: 0.752924029, quantity: 0.176 },
    { height: 0.285, speed: 0.754150201, quantity: 0.177 },
    { height: 0.286, speed: 0.755352516, quantity: 0.178 },
    { height: 0.286, speed: 0.75655483, quantity: 0.179 },
    { height: 0.287, speed: 0.757757145, quantity: 0.180 },
    { height: 0.288, speed: 0.75895946, quantity: 0.181 },
    { height: 0.289, speed: 0.760155708, quantity: 0.182 },
    { height: 0.29, speed: 0.761333453, quantity: 0.183 },
    { height: 0.291, speed: 0.762511198, quantity: 0.184 },
    { height: 0.291, speed: 0.763688942, quantity: 0.185 },
    { height: 0.292, speed: 0.764866687, quantity: 0.186 },
    { height: 0.293, speed: 0.766035765, quantity: 0.187 },
    { height: 0.294, speed: 0.767189649, quantity: 0.188 },
    { height: 0.295, speed: 0.768343534, quantity: 0.189 },
    { height: 0.295, speed: 0.769497419, quantity: 0.190 },
    { height: 0.296, speed: 0.770651303, quantity: 0.191 },
    { height: 0.297, speed: 0.771795762, quantity: 0.192 },
    { height: 0.298, speed: 0.77292647, quantity: 0.193 },
    { height: 0.299, speed: 0.774057178, quantity: 0.194 },
    { height: 0.299, speed: 0.775187886, quantity: 0.195 },
    { height: 0.3, speed: 0.776318594, quantity: 0.196 },
    { height: 0.301, speed: 0.777440797, quantity: 0.197 },
    { height: 0.302, speed: 0.778548986, quantity: 0.198 },
    { height: 0.302, speed: 0.779657176, quantity: 0.199 },
    { height: 0.303, speed: 0.780765366, quantity: 0.200 },
    { height: 0.304, speed: 0.781873556, quantity: 0.201 },
    { height: 0.305, speed: 0.782975691, quantity: 0.202 },
    { height: 0.306, speed: 0.784061997, quantity: 0.203 },
    { height: 0.306, speed: 0.785148302, quantity: 0.204 },
    { height: 0.307, speed: 0.786234607, quantity: 0.205 },
    { height: 0.308, speed: 0.787320912, quantity: 0.206 },
    { height: 0.309, speed: 0.788405007, quantity: 0.207 },
    { height: 0.31, speed: 0.789470038, quantity: 0.208 },
    { height: 0.31, speed: 0.790535069, quantity: 0.209 },
    { height: 0.311, speed: 0.791600101, quantity: 0.210 },
    { height: 0.312, speed: 0.792665132, quantity: 0.211 },
    { height: 0.313, speed: 0.793730163, quantity: 0.212 },
    { height: 0.313, speed: 0.794777405, quantity: 0.213 },
    { height: 0.314, speed: 0.79582175, quantity: 0.214 },
    { height: 0.315, speed: 0.796866096, quantity: 0.215 },
    { height: 0.316, speed: 0.797910442, quantity: 0.216 },
    { height: 0.316, speed: 0.798954787, quantity: 0.217 },
    { height: 0.317, speed: 0.799988159, quantity: 0.218 },
    { height: 0.318, speed: 0.801012386, quantity: 0.219 },
    { height: 0.319, speed: 0.802036613, quantity: 0.220 },
    { height: 0.319, speed: 0.80306084, quantity: 0.221 },
    { height: 0.32, speed: 0.804085067, quantity: 0.222 },
    { height: 0.321, speed: 0.805106147, quantity: 0.223 },
    { height: 0.322, speed: 0.806110802, quantity: 0.224 },
    { height: 0.322, speed: 0.807115457, quantity: 0.225 },
    { height: 0.323, speed: 0.808120113, quantity: 0.226 },
    { height: 0.324, speed: 0.809124768, quantity: 0.227 },
    { height: 0.325, speed: 0.810129424, quantity: 0.228 },
    { height: 0.325, speed: 0.81112062, quantity: 0.229 },
    { height: 0.326, speed: 0.812106231, quantity: 0.230 },
    { height: 0.327, speed: 0.813091842, quantity: 0.231 },
    { height: 0.328, speed: 0.814077453, quantity: 0.232 },
    { height: 0.328, speed: 0.815063064, quantity: 0.233 },
    { height: 0.329, speed: 0.816045269, quantity: 0.234 },
    { height: 0.33, speed: 0.817012344, quantity: 0.235 },
    { height: 0.331, speed: 0.817979419, quantity: 0.236 },
    { height: 0.331, speed: 0.818946494, quantity: 0.237 },
    { height: 0.332, speed: 0.819913569, quantity: 0.238 },
    { height: 0.333, speed: 0.820880644, quantity: 0.239 },
    { height: 0.334, speed: 0.821837027, quantity: 0.240 },
    { height: 0.334, speed: 0.822786057, quantity: 0.241 },
    { height: 0.335, speed: 0.823735086, quantity: 0.242 },
    { height: 0.336, speed: 0.824684116, quantity: 0.243 },
    { height: 0.336, speed: 0.825633145, quantity: 0.244 },
    { height: 0.337, speed: 0.826582175, quantity: 0.245 },
    { height: 0.338, speed: 0.827514797, quantity: 0.246 },
    { height: 0.339, speed: 0.828446254, quantity: 0.247 },
    { height: 0.339, speed: 0.829377712, quantity: 0.248 },
    { height: 0.34, speed: 0.830309169, quantity: 0.249 },
    { height: 0.341, speed: 0.831240626, quantity: 0.250 },
    { height: 0.341, speed: 0.832168521, quantity: 0.251 },
    { height: 0.342, speed: 0.833082863, quantity: 0.252 },
    { height: 0.343, speed: 0.833997205, quantity: 0.253 },
    { height: 0.344, speed: 0.834911548, quantity: 0.254 },
    { height: 0.344, speed: 0.83582589, quantity: 0.255 },
    { height: 0.345, speed: 0.836740232, quantity: 0.256 },
    { height: 0.346, speed: 0.837647628, quantity: 0.257 },
    { height: 0.346, speed: 0.838545296, quantity: 0.258 },
    { height: 0.347, speed: 0.839442965, quantity: 0.259 },
    { height: 0.348, speed: 0.840340633, quantity: 0.260 },
    { height: 0.349, speed: 0.841238301, quantity: 0.261 },
    { height: 0.349, speed: 0.842135969, quantity: 0.262 },
    { height: 0.35, speed: 0.843024542, quantity: 0.263 },
    { height: 0.351, speed: 0.843905962, quantity: 0.264 },
    { height: 0.351, speed: 0.844787382, quantity: 0.265 },
    { height: 0.352, speed: 0.845668801, quantity: 0.266 },
    { height: 0.353, speed: 0.846550221, quantity: 0.267 },
    { height: 0.354, speed: 0.847431641, quantity: 0.268 },
    { height: 0.354, speed: 0.848302949, quantity: 0.269 },
    { height: 0.355, speed: 0.849168531, quantity: 0.270 },
    { height: 0.356, speed: 0.850034114, quantity: 0.271 },
    { height: 0.356, speed: 0.850899697, quantity: 0.272 },
    { height: 0.357, speed: 0.851765279, quantity: 0.273 },
    { height: 0.358, speed: 0.852630862, quantity: 0.274 },
    { height: 0.358, speed: 0.853486351, quantity: 0.275 },
    { height: 0.359, speed: 0.854336494, quantity: 0.276 },
    { height: 0.36, speed: 0.855186637, quantity: 0.277 },
    { height: 0.361, speed: 0.856036779, quantity: 0.278 },
    { height: 0.361, speed: 0.856886922, quantity: 0.279 },
    { height: 0.362, speed: 0.857737065, quantity: 0.280 },
    { height: 0.363, speed: 0.858578079, quantity: 0.281 },
    { height: 0.363, speed: 0.859413166, quantity: 0.282 },
    { height: 0.364, speed: 0.860248252, quantity: 0.283 },
    { height: 0.365, speed: 0.861083339, quantity: 0.284 },
    { height: 0.365, speed: 0.861918425, quantity: 0.285 },
    { height: 0.366, speed: 0.862753512, quantity: 0.286 },
    { height: 0.367, speed: 0.863581297, quantity: 0.287 },
    { height: 0.367, speed: 0.864401698, quantity: 0.288 },
    { height: 0.368, speed: 0.865222099, quantity: 0.289 },
    { height: 0.369, speed: 0.866042501, quantity: 0.290 },
    { height: 0.369, speed: 0.866862902, quantity: 0.291 },
    { height: 0.37, speed: 0.867683303, quantity: 0.292 },
    { height: 0.371, speed: 0.868499015, quantity: 0.293 },
    { height: 0.371, speed: 0.869305089, quantity: 0.294 },
    { height: 0.372, speed: 0.870111163, quantity: 0.295 },
    { height: 0.373, speed: 0.870917237, quantity: 0.296 },
    { height: 0.374, speed: 0.871723311, quantity: 0.297 },
    { height: 0.374, speed: 0.872529385, quantity: 0.298 },
    { height: 0.375, speed: 0.873334093, quantity: 0.299 },
    { height: 0.376, speed: 0.874126186, quantity: 0.300 },
    { height: 0.376, speed: 0.874918279, quantity: 0.301 },
    { height: 0.377, speed: 0.875710372, quantity: 0.302 },
    { height: 0.378, speed: 0.876502465, quantity: 0.303 },
    { height: 0.378, speed: 0.877294558, quantity: 0.304 },
    { height: 0.379, speed: 0.878086651, quantity: 0.305 },
    { height: 0.38, speed: 0.8788677, quantity: 0.306 },
    { height: 0.38, speed: 0.879646147, quantity: 0.307 },
    { height: 0.381, speed: 0.880424594, quantity: 0.308 },
    { height: 0.382, speed: 0.881203041, quantity: 0.309 },
    { height: 0.382, speed: 0.881981488, quantity: 0.310 },
    { height: 0.383, speed: 0.882759935, quantity: 0.311 },
    { height: 0.384, speed: 0.883532209, quantity: 0.312 },
    { height: 0.384, speed: 0.884297334, quantity: 0.313 },
    { height: 0.385, speed: 0.885062458, quantity: 0.314 },
    { height: 0.386, speed: 0.885827583, quantity: 0.315 },
    { height: 0.386, speed: 0.886592707, quantity: 0.316 },
    { height: 0.387, speed: 0.887357831, quantity: 0.317 },
    { height: 0.388, speed: 0.888122165, quantity: 0.318 },
    { height: 0.388, speed: 0.88887428, quantity: 0.319 },
    { height: 0.389, speed: 0.889626395, quantity: 0.320 },
    { height: 0.39, speed: 0.89037851, quantity: 0.321 },
    { height: 0.39, speed: 0.891130625, quantity: 0.322 },
    { height: 0.391, speed: 0.89188274, quantity: 0.323 },
    { height: 0.392, speed: 0.892634855, quantity: 0.324 },
    { height: 0.392, speed: 0.89337931, quantity: 0.325 },
    { height: 0.393, speed: 0.894118718, quantity: 0.326 },
    { height: 0.393, speed: 0.894858126, quantity: 0.327 },
    { height: 0.394, speed: 0.895597534, quantity: 0.328 },
    { height: 0.395, speed: 0.896336942, quantity: 0.329 },
    { height: 0.395, speed: 0.89707635, quantity: 0.330 },
    { height: 0.396, speed: 0.897814634, quantity: 0.331 },
    { height: 0.397, speed: 0.898541628, quantity: 0.332 },
    { height: 0.397, speed: 0.899268622, quantity: 0.333 },
    { height: 0.398, speed: 0.899995615, quantity: 0.334 },
    { height: 0.399, speed: 0.900722609, quantity: 0.335 },
    { height: 0.399, speed: 0.901449603, quantity: 0.336 },
    { height: 0.4, speed: 0.902176597, quantity: 0.337 },
    { height: 0.401, speed: 0.90289722, quantity: 0.338 },
    { height: 0.401, speed: 0.903612083, quantity: 0.339 },
    { height: 0.402, speed: 0.904326945, quantity: 0.340 },
    { height: 0.403, speed: 0.905041808, quantity: 0.341 },
    { height: 0.403, speed: 0.90575667, quantity: 0.342 },
    { height: 0.404, speed: 0.906471533, quantity: 0.343 },
    { height: 0.405, speed: 0.907186396, quantity: 0.344 },
    { height: 0.405, speed: 0.907890493, quantity: 0.345 },
    { height: 0.406, speed: 0.908593499, quantity: 0.346 },
    { height: 0.406, speed: 0.909296504, quantity: 0.347 },
    { height: 0.407, speed: 0.90999951, quantity: 0.348 },
    { height: 0.408, speed: 0.910702515, quantity: 0.349 },
    { height: 0.408, speed: 0.91140552, quantity: 0.350 },
    { height: 0.409, speed: 0.912105743, quantity: 0.351 },
    { height: 0.41, speed: 0.912797157, quantity: 0.352 },
    { height: 0.41, speed: 0.91348857, quantity: 0.353 },
    { height: 0.411, speed: 0.914179983, quantity: 0.354 },
    { height: 0.412, speed: 0.914871396, quantity: 0.355 },
    { height: 0.412, speed: 0.915562809, quantity: 0.356 },
    { height: 0.413, speed: 0.916254223, quantity: 0.357 },
    { height: 0.414, speed: 0.91693971, quantity: 0.358 },
    { height: 0.414, speed: 0.917619787, quantity: 0.359 },
    { height: 0.415, speed: 0.918299864, quantity: 0.360 },
    { height: 0.415, speed: 0.918979942, quantity: 0.361 },
    { height: 0.416, speed: 0.919660019, quantity: 0.362 },
    { height: 0.417, speed: 0.920340096, quantity: 0.363 },
    { height: 0.417, speed: 0.921020174, quantity: 0.364 },
    { height: 0.418, speed: 0.921691852, quantity: 0.365 },
    { height: 0.419, speed: 0.922360842, quantity: 0.366 },
    { height: 0.419, speed: 0.923029832, quantity: 0.367 },
    { height: 0.42, speed: 0.923698821, quantity: 0.368 },
    { height: 0.42, speed: 0.924367811, quantity: 0.369 },
    { height: 0.421, speed: 0.9250368, quantity: 0.370 },
    { height: 0.422, speed: 0.92570579, quantity: 0.371 },
    { height: 0.422, speed: 0.926364524, quantity: 0.372 },
    { height: 0.423, speed: 0.927022666, quantity: 0.373 },
    { height: 0.424, speed: 0.927680807, quantity: 0.374 },
    { height: 0.424, speed: 0.928338949, quantity: 0.375 },
    { height: 0.425, speed: 0.928997091, quantity: 0.376 },
    { height: 0.426, speed: 0.929655233, quantity: 0.377 },
    { height: 0.426, speed: 0.93031244, quantity: 0.378 },
    { height: 0.427, speed: 0.930959967, quantity: 0.379 },
    { height: 0.427, speed: 0.931607493, quantity: 0.380 },
    { height: 0.428, speed: 0.93225502, quantity: 0.381 },
    { height: 0.429, speed: 0.932902546, quantity: 0.382 },
    { height: 0.429, speed: 0.933550073, quantity: 0.383 },
    { height: 0.43, speed: 0.934197599, quantity: 0.384 },
    { height: 0.431, speed: 0.934843187, quantity: 0.385 },
    { height: 0.431, speed: 0.935480323, quantity: 0.386 },
    { height: 0.432, speed: 0.936117459, quantity: 0.387 },
    { height: 0.432, speed: 0.936754595, quantity: 0.388 },
    { height: 0.433, speed: 0.937391731, quantity: 0.389 },
    { height: 0.434, speed: 0.938028867, quantity: 0.390 },
    { height: 0.434, speed: 0.938666003, quantity: 0.391 },
    { height: 0.435, speed: 0.93930067, quantity: 0.392 },
    { height: 0.436, speed: 0.939927633, quantity: 0.393 },
    { height: 0.436, speed: 0.940554596, quantity: 0.394 },
    { height: 0.437, speed: 0.941181559, quantity: 0.395 },
    { height: 0.437, speed: 0.941808522, quantity: 0.396 },
    { height: 0.438, speed: 0.942435485, quantity: 0.397 },
    { height: 0.439, speed: 0.943062448, quantity: 0.398 },
    { height: 0.439, speed: 0.943686846, quantity: 0.399 },
    { height: 0.44, speed: 0.944303847, quantity: 0.400 },
    { height: 0.44, speed: 0.944920848, quantity: 0.401 },
    { height: 0.441, speed: 0.945537848, quantity: 0.402 },
    { height: 0.442, speed: 0.946154849, quantity: 0.403 },
    { height: 0.442, speed: 0.94677185, quantity: 0.404 },
    { height: 0.443, speed: 0.94738885, quantity: 0.405 },
    { height: 0.444, speed: 0.948003581, quantity: 0.406 },
    { height: 0.444, speed: 0.948610823, quantity: 0.407 },
    { height: 0.445, speed: 0.949218065, quantity: 0.408 },
    { height: 0.445, speed: 0.949825307, quantity: 0.409 },
    { height: 0.446, speed: 0.950432549, quantity: 0.410 },
    { height: 0.447, speed: 0.951039791, quantity: 0.411 },
    { height: 0.447, speed: 0.951647034, quantity: 0.412 },
    { height: 0.448, speed: 0.952252654, quantity: 0.413 },
    { height: 0.448, speed: 0.952850335, quantity: 0.414 },
    { height: 0.449, speed: 0.953448016, quantity: 0.415 },
    { height: 0.45, speed: 0.954045697, quantity: 0.416 },
    { height: 0.45, speed: 0.954643378, quantity: 0.417 },
    { height: 0.451, speed: 0.955241059, quantity: 0.418 },
    { height: 0.452, speed: 0.95583874, quantity: 0.419 },
    { height: 0.452, speed: 0.956435766, quantity: 0.420 },
    { height: 0.453, speed: 0.957024077, quantity: 0.421 },
    { height: 0.453, speed: 0.957612387, quantity: 0.422 },
    { height: 0.454, speed: 0.958200698, quantity: 0.423 },
    { height: 0.455, speed: 0.958789009, quantity: 0.424 },
    { height: 0.455, speed: 0.959377319, quantity: 0.425 },
    { height: 0.456, speed: 0.95996563, quantity: 0.426 },
    { height: 0.456, speed: 0.96055394, quantity: 0.427 },
    { height: 0.457, speed: 0.961133664, quantity: 0.428 },
    { height: 0.458, speed: 0.961712789, quantity: 0.429 },
    { height: 0.458, speed: 0.962291913, quantity: 0.430 },
    { height: 0.459, speed: 0.962871038, quantity: 0.431 },
    { height: 0.459, speed: 0.963450163, quantity: 0.432 },
    { height: 0.46, speed: 0.964029288, quantity: 0.433 },
    { height: 0.461, speed: 0.964608413, quantity: 0.434 },
    { height: 0.461, speed: 0.965180637, quantity: 0.435 },
    { height: 0.462, speed: 0.965750755, quantity: 0.436 },
    { height: 0.462, speed: 0.966320873, quantity: 0.437 },
    { height: 0.463, speed: 0.966890991, quantity: 0.438 },
    { height: 0.464, speed: 0.967461109, quantity: 0.439 },
    { height: 0.464, speed: 0.968031227, quantity: 0.440 },
    { height: 0.465, speed: 0.968601346, quantity: 0.441 },
    { height: 0.465, speed: 0.969166468, quantity: 0.442 },
    { height: 0.466, speed: 0.969727752, quantity: 0.443 },
    { height: 0.467, speed: 0.970289036, quantity: 0.444 },
    { height: 0.467, speed: 0.970850321, quantity: 0.445 },
    { height: 0.468, speed: 0.971411605, quantity: 0.446 },
    { height: 0.468, speed: 0.971972889, quantity: 0.447 },
    { height: 0.469, speed: 0.972534174, quantity: 0.448 },
    { height: 0.47, speed: 0.97309256, quantity: 0.449 },
    { height: 0.47, speed: 0.973645178, quantity: 0.450 },
    { height: 0.471, speed: 0.974197796, quantity: 0.451 },
    { height: 0.471, speed: 0.974750414, quantity: 0.452 },
    { height: 0.472, speed: 0.975303032, quantity: 0.453 },
    { height: 0.473, speed: 0.975855649, quantity: 0.454 },
    { height: 0.473, speed: 0.976408267, quantity: 0.455 },
    { height: 0.474, speed: 0.976960253, quantity: 0.456 },
    { height: 0.474, speed: 0.977504366, quantity: 0.457 },
    { height: 0.475, speed: 0.97804848, quantity: 0.458 },
    { height: 0.476, speed: 0.978592593, quantity: 0.459 },
    { height: 0.476, speed: 0.979136707, quantity: 0.460 },
    { height: 0.477, speed: 0.97968082, quantity: 0.461 },
    { height: 0.477, speed: 0.980224933, quantity: 0.462 },
    { height: 0.478, speed: 0.980769047, quantity: 0.463 },
    { height: 0.479, speed: 0.98130659, quantity: 0.464 },
    { height: 0.479, speed: 0.981842355, quantity: 0.465 },
    { height: 0.48, speed: 0.982378121, quantity: 0.466 },
    { height: 0.48, speed: 0.982913887, quantity: 0.467 },
    { height: 0.481, speed: 0.983449652, quantity: 0.468 },
    { height: 0.482, speed: 0.983985418, quantity: 0.469 },
    { height: 0.482, speed: 0.984521183, quantity: 0.470 },
    { height: 0.483, speed: 0.985053062, quantity: 0.471 },
    { height: 0.483, speed: 0.985580631, quantity: 0.472 },
    { height: 0.484, speed: 0.986108201, quantity: 0.473 },
    { height: 0.485, speed: 0.98663577, quantity: 0.474 },
    { height: 0.485, speed: 0.987163339, quantity: 0.475 },
    { height: 0.486, speed: 0.987690908, quantity: 0.476 },
    { height: 0.486, speed: 0.988218477, quantity: 0.477 },
    { height: 0.487, speed: 0.988744941, quantity: 0.478 },
    { height: 0.488, speed: 0.98926446, quantity: 0.479 },
    { height: 0.488, speed: 0.98978398, quantity: 0.480 },
    { height: 0.489, speed: 0.990303499, quantity: 0.481 },
    { height: 0.489, speed: 0.990823019, quantity: 0.482 },
    { height: 0.49, speed: 0.991342538, quantity: 0.483 },
    { height: 0.491, speed: 0.991862057, quantity: 0.484 },
    { height: 0.491, speed: 0.992381577, quantity: 0.485 },
    { height: 0.492, speed: 0.992894941, quantity: 0.486 },
    { height: 0.492, speed: 0.993406553, quantity: 0.487 },
    { height: 0.493, speed: 0.993918164, quantity: 0.488 },
    { height: 0.494, speed: 0.994429775, quantity: 0.489 },
    { height: 0.494, speed: 0.994941386, quantity: 0.490 },
    { height: 0.495, speed: 0.995452997, quantity: 0.491 },
    { height: 0.495, speed: 0.995964608, quantity: 0.492 },
    { height: 0.496, speed: 0.996473122, quantity: 0.493 },
    { height: 0.496, speed: 0.996976962, quantity: 0.494 },
    { height: 0.497, speed: 0.997480801, quantity: 0.495 },
    { height: 0.498, speed: 0.997984641, quantity: 0.496 },
    { height: 0.498, speed: 0.998488481, quantity: 0.497 },
    { height: 0.499, speed: 0.998992321, quantity: 0.498 },
    { height: 0.499, speed: 0.99949616, quantity: 0.499 },
    { height: 0.5, speed: 1, quantity: 0.500 },
    { height: 0.501, speed: 1.000496201, quantity: 0.501 },
    { height: 0.501, speed: 1.000992401, quantity: 0.502 },
    { height: 0.502, speed: 1.001488602, quantity: 0.503 },
    { height: 0.502, speed: 1.001984803, quantity: 0.504 },
    { height: 0.503, speed: 1.002481003, quantity: 0.505 },
    { height: 0.504, speed: 1.002977204, quantity: 0.506 },
    { height: 0.504, speed: 1.003473405, quantity: 0.507 },
    { height: 0.505, speed: 1.003965216, quantity: 0.508 },
    { height: 0.505, speed: 1.004453906, quantity: 0.509 },
    { height: 0.506, speed: 1.004942595, quantity: 0.510 },
    { height: 0.506, speed: 1.005431284, quantity: 0.511 },
    { height: 0.507, speed: 1.005919974, quantity: 0.512 },
    { height: 0.508, speed: 1.006408663, quantity: 0.513 },
    { height: 0.508, speed: 1.006897352, quantity: 0.514 },
    { height: 0.509, speed: 1.00738491, quantity: 0.515 },
    { height: 0.509, speed: 1.007866211, quantity: 0.516 },
    { height: 0.51, speed: 1.008347513, quantity: 0.517 },
    { height: 0.511, speed: 1.008828814, quantity: 0.518 },
    { height: 0.511, speed: 1.009310115, quantity: 0.519 },
    { height: 0.512, speed: 1.009791417, quantity: 0.520 },
    { height: 0.512, speed: 1.010272718, quantity: 0.521 },
    { height: 0.513, speed: 1.01075402, quantity: 0.522 },
    { height: 0.514, speed: 1.011230172, quantity: 0.523 },
    { height: 0.514, speed: 1.011704204, quantity: 0.524 },
    { height: 0.515, speed: 1.012178237, quantity: 0.525 },
    { height: 0.515, speed: 1.012652269, quantity: 0.526 },
    { height: 0.516, speed: 1.013126302, quantity: 0.527 },
    { height: 0.516, speed: 1.013600334, quantity: 0.528 },
    { height: 0.517, speed: 1.014074367, quantity: 0.529 },
    { height: 0.518, speed: 1.014546599, quantity: 0.530 },
    { height: 0.518, speed: 1.015013477, quantity: 0.531 },
    { height: 0.519, speed: 1.015480356, quantity: 0.532 },
    { height: 0.519, speed: 1.015947235, quantity: 0.533 },
    { height: 0.52, speed: 1.016414113, quantity: 0.534 },
    { height: 0.521, speed: 1.016880992, quantity: 0.535 },
    { height: 0.521, speed: 1.017347871, quantity: 0.536 },
    { height: 0.522, speed: 1.017814749, quantity: 0.537 },
    { height: 0.522, speed: 1.0182761, quantity: 0.538 },
    { height: 0.523, speed: 1.018735935, quantity: 0.539 },
    { height: 0.523, speed: 1.019195771, quantity: 0.540 },
    { height: 0.524, speed: 1.019655606, quantity: 0.541 },
    { height: 0.525, speed: 1.020115442, quantity: 0.542 },
    { height: 0.525, speed: 1.020575278, quantity: 0.543 },
    { height: 0.526, speed: 1.021035113, quantity: 0.544 },
    { height: 0.526, speed: 1.0214928, quantity: 0.545 },
    { height: 0.527, speed: 1.0219457, quantity: 0.546 },
    { height: 0.528, speed: 1.022398599, quantity: 0.547 },
    { height: 0.528, speed: 1.022851498, quantity: 0.548 },
    { height: 0.529, speed: 1.023304398, quantity: 0.549 },
    { height: 0.529, speed: 1.023757297, quantity: 0.550 },
    { height: 0.53, speed: 1.024210197, quantity: 0.551 },
    { height: 0.53, speed: 1.024663096, quantity: 0.552 },
    { height: 0.531, speed: 1.025110337, quantity: 0.553 },
    { height: 0.532, speed: 1.025556404, quantity: 0.554 },
    { height: 0.532, speed: 1.02600247, quantity: 0.555 },
    { height: 0.533, speed: 1.026448536, quantity: 0.556 },
    { height: 0.533, speed: 1.026894602, quantity: 0.557 },
    { height: 0.534, speed: 1.027340668, quantity: 0.558 },
    { height: 0.534, speed: 1.027786734, quantity: 0.559 },
    { height: 0.535, speed: 1.028230501, quantity: 0.560 },
    { height: 0.536, speed: 1.028669833, quantity: 0.561 },
    { height: 0.536, speed: 1.029109164, quantity: 0.562 },
    { height: 0.537, speed: 1.029548496, quantity: 0.563 },
    { height: 0.537, speed: 1.029987828, quantity: 0.564 },
    { height: 0.538, speed: 1.03042716, quantity: 0.565 },
    { height: 0.539, speed: 1.030866492, quantity: 0.566 },
    { height: 0.539, speed: 1.031305824, quantity: 0.567 },
    { height: 0.54, speed: 1.0317395, quantity: 0.568 },
    { height: 0.54, speed: 1.032172193, quantity: 0.569 },
    { height: 0.541, speed: 1.032604886, quantity: 0.570 },
    { height: 0.541, speed: 1.033037579, quantity: 0.571 },
    { height: 0.542, speed: 1.033470272, quantity: 0.572 },
    { height: 0.543, speed: 1.033902965, quantity: 0.573 },
    { height: 0.543, speed: 1.034335658, quantity: 0.574 },
    { height: 0.544, speed: 1.034765986, quantity: 0.575 },
    { height: 0.544, speed: 1.035192132, quantity: 0.576 },
    { height: 0.545, speed: 1.035618278, quantity: 0.577 },
    { height: 0.546, speed: 1.036044424, quantity: 0.578 },
    { height: 0.546, speed: 1.036470569, quantity: 0.579 },
    { height: 0.547, speed: 1.036896715, quantity: 0.580 },
    { height: 0.547, speed: 1.037322861, quantity: 0.581 },
    { height: 0.548, speed: 1.037749007, quantity: 0.582 },
    { height: 0.548, speed: 1.038169526, quantity: 0.583 },
    { height: 0.549, speed: 1.038589212, quantity: 0.584 },
    { height: 0.55, speed: 1.039008899, quantity: 0.585 },
    { height: 0.55, speed: 1.039428586, quantity: 0.586 },
    { height: 0.551, speed: 1.039848272, quantity: 0.587 },
    { height: 0.551, speed: 1.040267959, quantity: 0.588 },
    { height: 0.552, speed: 1.040687646, quantity: 0.589 },
    { height: 0.552, speed: 1.041104888, quantity: 0.590 },
    { height: 0.553, speed: 1.041518199, quantity: 0.591 },
    { height: 0.554, speed: 1.041931511, quantity: 0.592 },
    { height: 0.554, speed: 1.042344823, quantity: 0.593 },
    { height: 0.555, speed: 1.042758135, quantity: 0.594 },
    { height: 0.555, speed: 1.043171447, quantity: 0.595 },
    { height: 0.556, speed: 1.043584759, quantity: 0.596 },
    { height: 0.557, speed: 1.043998071, quantity: 0.597 },
    { height: 0.557, speed: 1.044405718, quantity: 0.598 },
    { height: 0.558, speed: 1.044812736, quantity: 0.599 },
    { height: 0.558, speed: 1.045219754, quantity: 0.600 },
    { height: 0.559, speed: 1.045626772, quantity: 0.601 },
    { height: 0.559, speed: 1.04603379, quantity: 0.602 },
    { height: 0.56, speed: 1.046440809, quantity: 0.603 },
    { height: 0.561, speed: 1.046847827, quantity: 0.604 },
    { height: 0.561, speed: 1.047252216, quantity: 0.605 },
    { height: 0.562, speed: 1.047653018, quantity: 0.606 },
    { height: 0.562, speed: 1.04805382, quantity: 0.607 },
    { height: 0.563, speed: 1.048454622, quantity: 0.608 },
    { height: 0.563, speed: 1.048855424, quantity: 0.609 },
    { height: 0.564, speed: 1.049256226, quantity: 0.610 },
    { height: 0.565, speed: 1.049657028, quantity: 0.611 },
    { height: 0.565, speed: 1.05005783, quantity: 0.612 },
    { height: 0.566, speed: 1.050452775, quantity: 0.613 },
    { height: 0.566, speed: 1.050847435, quantity: 0.614 },
    { height: 0.567, speed: 1.051242095, quantity: 0.615 },
    { height: 0.568, speed: 1.051636755, quantity: 0.616 },
    { height: 0.568, speed: 1.052031415, quantity: 0.617 },
    { height: 0.569, speed: 1.052426075, quantity: 0.618 },
    { height: 0.569, speed: 1.052820734, quantity: 0.619 },
    { height: 0.57, speed: 1.053212396, quantity: 0.620 },
    { height: 0.57, speed: 1.053600985, quantity: 0.621 },
    { height: 0.571, speed: 1.053989573, quantity: 0.622 },
    { height: 0.572, speed: 1.054378161, quantity: 0.623 },
    { height: 0.572, speed: 1.05476675, quantity: 0.624 },
    { height: 0.573, speed: 1.055155338, quantity: 0.625 },
    { height: 0.573, speed: 1.055543927, quantity: 0.626 },
    { height: 0.574, speed: 1.055932241, quantity: 0.627 },
    { height: 0.575, speed: 1.056314825, quantity: 0.628 },
    { height: 0.575, speed: 1.05669741, quantity: 0.629 },
    { height: 0.576, speed: 1.057079994, quantity: 0.630 },
    { height: 0.576, speed: 1.057462579, quantity: 0.631 },
    { height: 0.577, speed: 1.057845163, quantity: 0.632 },
    { height: 0.577, speed: 1.058227748, quantity: 0.633 },
    { height: 0.578, speed: 1.058610333, quantity: 0.634 },
    { height: 0.579, speed: 1.058989288, quantity: 0.635 },
    { height: 0.579, speed: 1.059365933, quantity: 0.636 },
    { height: 0.58, speed: 1.059742577, quantity: 0.637 },
    { height: 0.58, speed: 1.060119222, quantity: 0.638 },
    { height: 0.581, speed: 1.060495867, quantity: 0.639 },
    { height: 0.581, speed: 1.060872512, quantity: 0.640 },
    { height: 0.582, speed: 1.061249156, quantity: 0.641 },
    { height: 0.583, speed: 1.061624675, quantity: 0.642 },
    { height: 0.583, speed: 1.061995441, quantity: 0.643 },
    { height: 0.584, speed: 1.062366207, quantity: 0.644 },
    { height: 0.584, speed: 1.062736973, quantity: 0.645 },
    { height: 0.585, speed: 1.063107739, quantity: 0.646 },
    { height: 0.586, speed: 1.063478505, quantity: 0.647 },
    { height: 0.586, speed: 1.063849271, quantity: 0.648 },
    { height: 0.587, speed: 1.064220037, quantity: 0.649 },
    { height: 0.587, speed: 1.06458621, quantity: 0.650 },
    { height: 0.588, speed: 1.064951154, quantity: 0.651 },
    { height: 0.588, speed: 1.065316099, quantity: 0.652 },
    { height: 0.589, speed: 1.065681044, quantity: 0.653 },
    { height: 0.59, speed: 1.066045988, quantity: 0.654 },
    { height: 0.59, speed: 1.066410933, quantity: 0.655 },
    { height: 0.591, speed: 1.066775878, quantity: 0.656 },
    { height: 0.591, speed: 1.067138485, quantity: 0.657 },
    { height: 0.592, speed: 1.067497663, quantity: 0.658 },
    { height: 0.593, speed: 1.067856841, quantity: 0.659 },
    { height: 0.593, speed: 1.068216019, quantity: 0.660 },
    { height: 0.594, speed: 1.068575197, quantity: 0.661 },
    { height: 0.594, speed: 1.068934375, quantity: 0.662 },
    { height: 0.595, speed: 1.069293553, quantity: 0.663 },
    { height: 0.595, speed: 1.06965249, quantity: 0.664 },
    { height: 0.596, speed: 1.070005952, quantity: 0.665 },
    { height: 0.597, speed: 1.070359415, quantity: 0.666 },
    { height: 0.597, speed: 1.070712877, quantity: 0.667 },
    { height: 0.598, speed: 1.07106634, quantity: 0.668 },
    { height: 0.598, speed: 1.071419802, quantity: 0.669 },
    { height: 0.599, speed: 1.071773264, quantity: 0.670 },
    { height: 0.6, speed: 1.072126727, quantity: 0.671 },
    { height: 0.6, speed: 1.072476214, quantity: 0.672 },
    { height: 0.601, speed: 1.072824009, quantity: 0.673 },
    { height: 0.601, speed: 1.073171804, quantity: 0.674 },
    { height: 0.602, speed: 1.073519599, quantity: 0.675 },
    { height: 0.602, speed: 1.073867394, quantity: 0.676 },
    { height: 0.603, speed: 1.074215189, quantity: 0.677 },
    { height: 0.604, speed: 1.074562984, quantity: 0.678 },
    { height: 0.604, speed: 1.074908614, quantity: 0.679 },
    { height: 0.605, speed: 1.075250787, quantity: 0.680 },
    { height: 0.605, speed: 1.075592959, quantity: 0.681 },
    { height: 0.606, speed: 1.075935132, quantity: 0.682 },
    { height: 0.607, speed: 1.076277304, quantity: 0.683 },
    { height: 0.607, speed: 1.076619477, quantity: 0.684 },
    { height: 0.608, speed: 1.07696165, quantity: 0.685 },
    { height: 0.608, speed: 1.077303295, quantity: 0.686 },
    { height: 0.609, speed: 1.077639886, quantity: 0.687 },
    { height: 0.609, speed: 1.077976478, quantity: 0.688 },
    { height: 0.61, speed: 1.07831307, quantity: 0.689 },
    { height: 0.611, speed: 1.078649662, quantity: 0.690 },
    { height: 0.611, speed: 1.078986254, quantity: 0.691 },
    { height: 0.612, speed: 1.079322846, quantity: 0.692 },
    { height: 0.612, speed: 1.079659438, quantity: 0.693 },
    { height: 0.613, speed: 1.07999142, quantity: 0.694 },
    { height: 0.614, speed: 1.080322469, quantity: 0.695 },
    { height: 0.614, speed: 1.080653519, quantity: 0.696 },
    { height: 0.615, speed: 1.080984569, quantity: 0.697 },
    { height: 0.615, speed: 1.081315619, quantity: 0.698 },
    { height: 0.616, speed: 1.081646669, quantity: 0.699 },
    { height: 0.616, speed: 1.081977718, quantity: 0.700 },
    { height: 0.617, speed: 1.082305471, quantity: 0.701 },
    { height: 0.618, speed: 1.082631015, quantity: 0.702 },
    { height: 0.618, speed: 1.082956558, quantity: 0.703 },
    { height: 0.619, speed: 1.083282101, quantity: 0.704 },
    { height: 0.619, speed: 1.083607644, quantity: 0.705 },
    { height: 0.62, speed: 1.083933187, quantity: 0.706 },
    { height: 0.621, speed: 1.084258731, quantity: 0.707 },
    { height: 0.621, speed: 1.084582101, quantity: 0.708 },
    { height: 0.622, speed: 1.08490217, quantity: 0.709 },
    { height: 0.622, speed: 1.085222239, quantity: 0.710 },
    { height: 0.623, speed: 1.085542308, quantity: 0.711 },
    { height: 0.624, speed: 1.085862377, quantity: 0.712 },
    { height: 0.624, speed: 1.086182446, quantity: 0.713 },
    { height: 0.625, speed: 1.086502514, quantity: 0.714 },
    { height: 0.625, speed: 1.086821341, quantity: 0.715 },
    { height: 0.626, speed: 1.087135965, quantity: 0.716 },
    { height: 0.627, speed: 1.087450589, quantity: 0.717 },
    { height: 0.627, speed: 1.087765212, quantity: 0.718 },
    { height: 0.628, speed: 1.088079836, quantity: 0.719 },
    { height: 0.628, speed: 1.088394459, quantity: 0.720 },
    { height: 0.629, speed: 1.088709083, quantity: 0.721 },
    { height: 0.629, speed: 1.089023197, quantity: 0.722 },
    { height: 0.63, speed: 1.089332401, quantity: 0.723 },
    { height: 0.631, speed: 1.089641605, quantity: 0.724 },
    { height: 0.631, speed: 1.08995081, quantity: 0.725 },
    { height: 0.632, speed: 1.090260014, quantity: 0.726 },
    { height: 0.632, speed: 1.090569218, quantity: 0.727 },
    { height: 0.633, speed: 1.090878422, quantity: 0.728 },
    { height: 0.634, speed: 1.091187645, quantity: 0.729 },
    { height: 0.634, speed: 1.091491453, quantity: 0.730 },
    { height: 0.635, speed: 1.09179526, quantity: 0.731 },
    { height: 0.635, speed: 1.092099067, quantity: 0.732 },
    { height: 0.636, speed: 1.092402875, quantity: 0.733 },
    { height: 0.637, speed: 1.092706682, quantity: 0.734 },
    { height: 0.637, speed: 1.09301049, quantity: 0.735 },
    { height: 0.638, speed: 1.093314297, quantity: 0.736 },
    { height: 0.638, speed: 1.093613065, quantity: 0.737 },
    { height: 0.639, speed: 1.093911495, quantity: 0.738 },
    { height: 0.64, speed: 1.094209925, quantity: 0.739 },
    { height: 0.64, speed: 1.094508355, quantity: 0.740 },
    { height: 0.641, speed: 1.094806785, quantity: 0.741 },
    { height: 0.641, speed: 1.095105215, quantity: 0.742 },
    { height: 0.642, speed: 1.095403645, quantity: 0.743 },
    { height: 0.643, speed: 1.095697155, quantity: 0.744 },
    { height: 0.643, speed: 1.095990224, quantity: 0.745 },
    { height: 0.644, speed: 1.096283293, quantity: 0.746 },
    { height: 0.644, speed: 1.096576361, quantity: 0.747 },
    { height: 0.645, speed: 1.09686943, quantity: 0.748 },
    { height: 0.646, speed: 1.097162499, quantity: 0.749 },
    { height: 0.646, speed: 1.097455568, quantity: 0.750 },
    { height: 0.647, speed: 1.097743609, quantity: 0.751 },
    { height: 0.647, speed: 1.098031329, quantity: 0.752 },
    { height: 0.648, speed: 1.09831905, quantity: 0.753 },
    { height: 0.649, speed: 1.09860677, quantity: 0.754 },
    { height: 0.649, speed: 1.09889449, quantity: 0.755 },
    { height: 0.65, speed: 1.09918221, quantity: 0.756 },
    { height: 0.65, speed: 1.099469902, quantity: 0.757 },
    { height: 0.651, speed: 1.099752283, quantity: 0.758 },
    { height: 0.652, speed: 1.100034664, quantity: 0.759 },
    { height: 0.652, speed: 1.100317045, quantity: 0.760 },
    { height: 0.653, speed: 1.100599426, quantity: 0.761 },
    { height: 0.653, speed: 1.100881807, quantity: 0.762 },
    { height: 0.654, speed: 1.101164188, quantity: 0.763 },
    { height: 0.655, speed: 1.101445953, quantity: 0.764 },
    { height: 0.655, speed: 1.101723, quantity: 0.765 },
    { height: 0.656, speed: 1.102000048, quantity: 0.766 },
    { height: 0.656, speed: 1.102277096, quantity: 0.767 },
    { height: 0.657, speed: 1.102554143, quantity: 0.768 },
    { height: 0.658, speed: 1.102831191, quantity: 0.769 },
    { height: 0.658, speed: 1.103108238, quantity: 0.770 },
    { height: 0.659, speed: 1.103383834, quantity: 0.771 },
    { height: 0.659, speed: 1.103655551, quantity: 0.772 },
    { height: 0.66, speed: 1.103927268, quantity: 0.773 },
    { height: 0.661, speed: 1.104198984, quantity: 0.774 },
    { height: 0.661, speed: 1.104470701, quantity: 0.775 },
    { height: 0.662, speed: 1.104742418, quantity: 0.776 },
    { height: 0.662, speed: 1.105014135, quantity: 0.777 },
    { height: 0.663, speed: 1.105283307, quantity: 0.778 },
    { height: 0.664, speed: 1.105549691, quantity: 0.779 },
    { height: 0.664, speed: 1.105816076, quantity: 0.780 },
    { height: 0.665, speed: 1.106082461, quantity: 0.781 },
    { height: 0.666, speed: 1.106348845, quantity: 0.782 },
    { height: 0.666, speed: 1.10661523, quantity: 0.783 },
    { height: 0.667, speed: 1.106881614, quantity: 0.784 },
    { height: 0.667, speed: 1.107144094, quantity: 0.785 },
    { height: 0.668, speed: 1.107405141, quantity: 0.786 },
    { height: 0.669, speed: 1.107666189, quantity: 0.787 },
    { height: 0.669, speed: 1.107927236, quantity: 0.788 },
    { height: 0.67, speed: 1.108188284, quantity: 0.789 },
    { height: 0.67, speed: 1.108449332, quantity: 0.790 },
    { height: 0.671, speed: 1.108710179, quantity: 0.791 },
    { height: 0.672, speed: 1.108965881, quantity: 0.792 },
    { height: 0.672, speed: 1.109221583, quantity: 0.793 },
    { height: 0.673, speed: 1.109477285, quantity: 0.794 },
    { height: 0.673, speed: 1.109732987, quantity: 0.795 },
    { height: 0.674, speed: 1.109988689, quantity: 0.796 },
    { height: 0.675, speed: 1.110244391, quantity: 0.797 },
    { height: 0.675, speed: 1.11049797, quantity: 0.798 },
    { height: 0.676, speed: 1.110748315, quantity: 0.799 },
    { height: 0.677, speed: 1.110998659, quantity: 0.800 },
    { height: 0.677, speed: 1.111249003, quantity: 0.801 },
    { height: 0.678, speed: 1.111499347, quantity: 0.802 },
    { height: 0.678, speed: 1.111749691, quantity: 0.803 },
    { height: 0.679, speed: 1.112000035, quantity: 0.804 },
    { height: 0.68, speed: 1.112246031, quantity: 0.805 },
    { height: 0.68, speed: 1.112491001, quantity: 0.806 },
    { height: 0.681, speed: 1.112735971, quantity: 0.807 },
    { height: 0.682, speed: 1.112980941, quantity: 0.808 },
    { height: 0.682, speed: 1.113225911, quantity: 0.809 },
    { height: 0.683, speed: 1.113470881, quantity: 0.810 },
    { height: 0.683, speed: 1.113714351, quantity: 0.811 },
    { height: 0.684, speed: 1.113953926, quantity: 0.812 },
    { height: 0.685, speed: 1.114193502, quantity: 0.813 },
    { height: 0.685, speed: 1.114433077, quantity: 0.814 },
    { height: 0.686, speed: 1.114672653, quantity: 0.815 },
    { height: 0.687, speed: 1.114912228, quantity: 0.816 },
    { height: 0.687, speed: 1.115151803, quantity: 0.817 },
    { height: 0.688, speed: 1.115387021, quantity: 0.818 },
    { height: 0.688, speed: 1.115621177, quantity: 0.819 },
    { height: 0.689, speed: 1.115855334, quantity: 0.820 },
    { height: 0.69, speed: 1.11608949, quantity: 0.821 },
    { height: 0.69, speed: 1.116323646, quantity: 0.822 },
    { height: 0.691, speed: 1.116557802, quantity: 0.823 },
    { height: 0.692, speed: 1.11678984, quantity: 0.824 },
    { height: 0.692, speed: 1.117018548, quantity: 0.825 },
    { height: 0.693, speed: 1.117247256, quantity: 0.826 },
    { height: 0.693, speed: 1.117475964, quantity: 0.827 },
    { height: 0.694, speed: 1.117704673, quantity: 0.828 },
    { height: 0.695, speed: 1.117933381, quantity: 0.829 },
    { height: 0.695, speed: 1.118161903, quantity: 0.830 },
    { height: 0.696, speed: 1.11838513, quantity: 0.831 },
    { height: 0.697, speed: 1.118608357, quantity: 0.832 },
    { height: 0.697, speed: 1.118831584, quantity: 0.833 },
    { height: 0.698, speed: 1.119054811, quantity: 0.834 },
    { height: 0.699, speed: 1.119278038, quantity: 0.835 },
    { height: 0.699, speed: 1.119501266, quantity: 0.836 },
    { height: 0.7, speed: 1.119720405, quantity: 0.837 },
    { height: 0.7, speed: 1.119938113, quantity: 0.838 },
    { height: 0.701, speed: 1.120155821, quantity: 0.839 },
    { height: 0.702, speed: 1.120373529, quantity: 0.840 },
    { height: 0.702, speed: 1.120591237, quantity: 0.841 },
    { height: 0.703, speed: 1.120808945, quantity: 0.842 },
    { height: 0.704, speed: 1.121023815, quantity: 0.843 },
    { height: 0.704, speed: 1.121235962, quantity: 0.844 },
    { height: 0.705, speed: 1.121448108, quantity: 0.845 },
    { height: 0.706, speed: 1.121660255, quantity: 0.846 },
    { height: 0.706, speed: 1.121872401, quantity: 0.847 },
    { height: 0.707, speed: 1.122084548, quantity: 0.848 },
    { height: 0.708, speed: 1.122294763, quantity: 0.849 },
    { height: 0.708, speed: 1.1225013, quantity: 0.850 },
    { height: 0.709, speed: 1.122707837, quantity: 0.851 },
    { height: 0.71, speed: 1.122914375, quantity: 0.852 },
    { height: 0.71, speed: 1.123120912, quantity: 0.853 },
    { height: 0.711, speed: 1.123327449, quantity: 0.854 },
    { height: 0.711, speed: 1.123532605, quantity: 0.855 },
    { height: 0.712, speed: 1.123733481, quantity: 0.856 },
    { height: 0.713, speed: 1.123934356, quantity: 0.857 },
    { height: 0.713, speed: 1.124135232, quantity: 0.858 },
    { height: 0.714, speed: 1.124336107, quantity: 0.859 },
    { height: 0.715, speed: 1.124536983, quantity: 0.860 },
    { height: 0.715, speed: 1.124736654, quantity: 0.861 },
    { height: 0.716, speed: 1.12493181, quantity: 0.862 },
    { height: 0.717, speed: 1.125126965, quantity: 0.863 },
    { height: 0.717, speed: 1.125322121, quantity: 0.864 },
    { height: 0.718, speed: 1.125517277, quantity: 0.865 },
    { height: 0.719, speed: 1.125712432, quantity: 0.866 },
    { height: 0.719, speed: 1.125906169, quantity: 0.867 },
    { height: 0.72, speed: 1.126095541, quantity: 0.868 },
    { height: 0.721, speed: 1.126284913, quantity: 0.869 },
    { height: 0.721, speed: 1.126474285, quantity: 0.870 },
    { height: 0.722, speed: 1.126663658, quantity: 0.871 },
    { height: 0.723, speed: 1.12685303, quantity: 0.872 },
    { height: 0.723, speed: 1.127040356, quantity: 0.873 },
    { height: 0.724, speed: 1.127223875, quantity: 0.874 },
    { height: 0.725, speed: 1.127407394, quantity: 0.875 },
    { height: 0.725, speed: 1.127590913, quantity: 0.876 },
    { height: 0.726, speed: 1.127774432, quantity: 0.877 },
    { height: 0.727, speed: 1.127957952, quantity: 0.878 },
    { height: 0.727, speed: 1.128138361, quantity: 0.879 },
    { height: 0.728, speed: 1.128315951, quantity: 0.880 },
    { height: 0.729, speed: 1.128493542, quantity: 0.881 },
    { height: 0.729, speed: 1.128671133, quantity: 0.882 },
    { height: 0.73, speed: 1.128848723, quantity: 0.883 },
    { height: 0.731, speed: 1.129026314, quantity: 0.884 },
    { height: 0.731, speed: 1.129199268, quantity: 0.885 },
    { height: 0.732, speed: 1.129370848, quantity: 0.886 },
    { height: 0.733, speed: 1.129542429, quantity: 0.887 },
    { height: 0.733, speed: 1.129714009, quantity: 0.888 },
    { height: 0.734, speed: 1.129885589, quantity: 0.889 },
    { height: 0.735, speed: 1.130056613, quantity: 0.890 },
    { height: 0.735, speed: 1.130222094, quantity: 0.891 },
    { height: 0.736, speed: 1.130387575, quantity: 0.892 },
    { height: 0.737, speed: 1.130553056, quantity: 0.893 },
    { height: 0.738, speed: 1.130718536, quantity: 0.894 },
    { height: 0.738, speed: 1.130884017, quantity: 0.895 },
    { height: 0.739, speed: 1.131046493, quantity: 0.896 },
    { height: 0.74, speed: 1.131205779, quantity: 0.897 },
    { height: 0.74, speed: 1.131365065, quantity: 0.898 },
    { height: 0.741, speed: 1.13152435, quantity: 0.899 },
    { height: 0.742, speed: 1.131683636, quantity: 0.900 },
    { height: 0.742, speed: 1.131842922, quantity: 0.901 },
    { height: 0.743, speed: 1.131996198, quantity: 0.902 },
    { height: 0.744, speed: 1.132149185, quantity: 0.903 },
    { height: 0.744, speed: 1.132302173, quantity: 0.904 },
    { height: 0.745, speed: 1.13245516, quantity: 0.905 },
    { height: 0.746, speed: 1.132608148, quantity: 0.906 },
    { height: 0.747, speed: 1.132757933, quantity: 0.907 },
    { height: 0.747, speed: 1.132904511, quantity: 0.908 },
    { height: 0.748, speed: 1.133051089, quantity: 0.909 },
    { height: 0.749, speed: 1.133197667, quantity: 0.910 },
    { height: 0.749, speed: 1.133344245, quantity: 0.911 },
    { height: 0.75, speed: 1.133490025, quantity: 0.912 },
    { height: 0.751, speed: 1.133630074, quantity: 0.913 },
    { height: 0.752, speed: 1.133770123, quantity: 0.914 },
    { height: 0.752, speed: 1.133910172, quantity: 0.915 },
    { height: 0.753, speed: 1.134050221, quantity: 0.916 },
    { height: 0.754, speed: 1.13419027, quantity: 0.917 },
    { height: 0.754, speed: 1.134324839, quantity: 0.918 },
    { height: 0.755, speed: 1.134458231, quantity: 0.919 },
    { height: 0.756, speed: 1.134591623, quantity: 0.920 },
    { height: 0.757, speed: 1.134725015, quantity: 0.921 },
    { height: 0.757, speed: 1.134858407, quantity: 0.922 },
    { height: 0.758, speed: 1.134987698, quantity: 0.923 },
    { height: 0.759, speed: 1.135114296, quantity: 0.924 },
    { height: 0.759, speed: 1.135240893, quantity: 0.925 },
    { height: 0.76, speed: 1.13536749, quantity: 0.926 },
    { height: 0.761, speed: 1.135494087, quantity: 0.927 },
    { height: 0.762, speed: 1.135617465, quantity: 0.928 },
    { height: 0.762, speed: 1.13573712, quantity: 0.929 },
    { height: 0.763, speed: 1.135856775, quantity: 0.930 },
    { height: 0.764, speed: 1.13597643, quantity: 0.931 },
    { height: 0.765, speed: 1.136096085, quantity: 0.932 },
    { height: 0.765, speed: 1.136212863, quantity: 0.933 },
    { height: 0.766, speed: 1.136325418, quantity: 0.934 },
    { height: 0.767, speed: 1.136437973, quantity: 0.935 },
    { height: 0.767, speed: 1.136550528, quantity: 0.936 },
    { height: 0.768, speed: 1.136663083, quantity: 0.937 },
    { height: 0.769, speed: 1.136772523, quantity: 0.938 },
    { height: 0.77, speed: 1.136877808, quantity: 0.939 },
    { height: 0.77, speed: 1.136983094, quantity: 0.940 },
    { height: 0.771, speed: 1.137088379, quantity: 0.941 },
    { height: 0.772, speed: 1.137193665, quantity: 0.942 },
    { height: 0.773, speed: 1.137294968, quantity: 0.943 },
    { height: 0.773, speed: 1.137392803, quantity: 0.944 },
    { height: 0.774, speed: 1.137490638, quantity: 0.945 },
    { height: 0.775, speed: 1.137588473, quantity: 0.946 },
    { height: 0.776, speed: 1.137686308, quantity: 0.947 },
    { height: 0.777, speed: 1.137778609, quantity: 0.948 },
    { height: 0.777, speed: 1.137868799, quantity: 0.949 },
    { height: 0.778, speed: 1.13795899, quantity: 0.950 },
    { height: 0.779, speed: 1.13804918, quantity: 0.951 },
    { height: 0.78, speed: 1.138139389, quantity: 0.952 },
    { height: 0.78, speed: 1.138221727, quantity: 0.953 },
    { height: 0.781, speed: 1.138304066, quantity: 0.954 },
    { height: 0.782, speed: 1.138386404, quantity: 0.955 },
    { height: 0.783, speed: 1.138468743, quantity: 0.956 },
    { height: 0.783, speed: 1.138548203, quantity: 0.957 },
    { height: 0.784, speed: 1.138622467, quantity: 0.958 },
    { height: 0.785, speed: 1.138696732, quantity: 0.959 },
    { height: 0.786, speed: 1.138770996, quantity: 0.960 },
    { height: 0.787, speed: 1.13884526, quantity: 0.961 },
    { height: 0.787, speed: 1.138912868, quantity: 0.962 },
    { height: 0.788, speed: 1.13897882, quantity: 0.963 },
    { height: 0.789, speed: 1.139044772, quantity: 0.964 },
    { height: 0.79, speed: 1.139110724, quantity: 0.965 },
    { height: 0.791, speed: 1.139173837, quantity: 0.966 },
    { height: 0.791, speed: 1.139231222, quantity: 0.967 },
    { height: 0.792, speed: 1.139288607, quantity: 0.968 },
    { height: 0.793, speed: 1.139345992, quantity: 0.969 },
    { height: 0.794, speed: 1.139403378, quantity: 0.970 },
    { height: 0.795, speed: 1.139452374, quantity: 0.971 },
    { height: 0.795, speed: 1.139500919, quantity: 0.972 },
    { height: 0.796, speed: 1.139549464, quantity: 0.973 },
    { height: 0.797, speed: 1.139598009, quantity: 0.974 },
    { height: 0.798, speed: 1.139640582, quantity: 0.975 },
    { height: 0.799, speed: 1.139679995, quantity: 0.976 },
    { height: 0.8, speed: 1.139719407, quantity: 0.977 },
    { height: 0.8, speed: 1.13975882, quantity: 0.978 },
    { height: 0.801, speed: 1.13979401, quantity: 0.979 },
    { height: 0.802, speed: 1.139823976, quantity: 0.980 },
    { height: 0.803, speed: 1.139853942, quantity: 0.981 },
    { height: 0.804, speed: 1.139883909, quantity: 0.982 },
    { height: 0.805, speed: 1.139910662, quantity: 0.983 },
    { height: 0.806, speed: 1.139930844, quantity: 0.984 },
    { height: 0.806, speed: 1.139951027, quantity: 0.985 },
    { height: 0.807, speed: 1.13997121, quantity: 0.986 },
    { height: 0.808, speed: 1.139988361, quantity: 0.987 },
    { height: 0.809, speed: 1.139998399, quantity: 0.988 },
    { height: 0.81, speed: 1.140008436, quantity: 0.989 },
    { height: 0.811, speed: 1.140018474, quantity: 0.990 },
    { height: 0.812, speed: 1.140024734, quantity: 0.991 },
    { height: 0.812, speed: 1.140024237, quantity: 0.992 },
    { height: 0.813, speed: 1.140023741, quantity: 0.993 },
    { height: 0.814, speed: 1.140023244, quantity: 0.994 },
    { height: 0.815, speed: 1.140017182, quantity: 0.995 },
    { height: 0.816, speed: 1.140005733, quantity: 0.996 },
    { height: 0.817, speed: 1.139994284, quantity: 0.997 },
    { height: 0.818, speed: 1.139982835, quantity: 0.998 },
    { height: 0.819, speed: 1.13996286, quantity: 0.999 },
    { height: 0.82, speed: 1.139940007, quantity: 1.000 },
    { height: 0.821, speed: 1.139917155, quantity: 1.001 },
    { height: 0.821, speed: 1.139893378, quantity: 1.002 },
    { height: 0.822, speed: 1.139858637, quantity: 1.003 },
    { height: 0.823, speed: 1.139823895, quantity: 1.004 },
    { height: 0.824, speed: 1.139789154, quantity: 1.005 },
    { height: 0.825, speed: 1.139748224, quantity: 1.006 },
    { height: 0.826, speed: 1.139701069, quantity: 1.007 },
    { height: 0.827, speed: 1.139653914, quantity: 1.008 },
    { height: 0.828, speed: 1.139606626, quantity: 1.009 },
    { height: 0.829, speed: 1.13954649, quantity: 1.010 },
    { height: 0.83, speed: 1.139486354, quantity: 1.011 },
    { height: 0.831, speed: 1.139426218, quantity: 1.012 },
    { height: 0.832, speed: 1.139357749, quantity: 1.013 },
    { height: 0.833, speed: 1.139284017, quantity: 1.014 },
    { height: 0.834, speed: 1.139210286, quantity: 1.015 },
    { height: 0.835, speed: 1.139132177, quantity: 1.016 },
    { height: 0.836, speed: 1.139044185, quantity: 1.017 },
    { height: 0.837, speed: 1.138956193, quantity: 1.018 },
    { height: 0.838, speed: 1.138866816, quantity: 1.019 },
    { height: 0.839, speed: 1.13876384, quantity: 1.020 },
    { height: 0.84, speed: 1.138660864, quantity: 1.021 },
    { height: 0.841, speed: 1.138558372, quantity: 1.022 },
    { height: 0.842, speed: 1.138439625, quantity: 1.023 },
    { height: 0.843, speed: 1.138320878, quantity: 1.024 },
    { height: 0.844, speed: 1.138202131, quantity: 1.025 },
    { height: 0.845, speed: 1.13806779, quantity: 1.026 },
    { height: 0.846, speed: 1.137932415, quantity: 1.027 },
    { height: 0.847, speed: 1.137797077, quantity: 1.028 },
    { height: 0.848, speed: 1.137644137, quantity: 1.029 },
    { height: 0.85, speed: 1.137491197, quantity: 1.030 },
    { height: 0.851, speed: 1.137335478, quantity: 1.031 },
    { height: 0.852, speed: 1.137163947, quantity: 1.032 },
    { height: 0.853, speed: 1.136992415, quantity: 1.033 },
    { height: 0.854, speed: 1.136813146, quantity: 1.034 },
    { height: 0.855, speed: 1.136621897, quantity: 1.035 },
    { height: 0.856, speed: 1.136430649, quantity: 1.036 },
    { height: 0.857, speed: 1.136224169, quantity: 1.037 },
    { height: 0.859, speed: 1.136011965, quantity: 1.038 },
    { height: 0.86, speed: 1.135796356, quantity: 1.039 },
    { height: 0.861, speed: 1.135561831, quantity: 1.040 },
    { height: 0.862, speed: 1.135327306, quantity: 1.041 },
    { height: 0.864, speed: 1.135076829, quantity: 1.042 },
    { height: 0.865, speed: 1.13481847, quantity: 1.043 },
    { height: 0.866, speed: 1.134553046, quantity: 1.044 },
    { height: 0.867, speed: 1.134269175, quantity: 1.045 },
    { height: 0.869, speed: 1.133985984, quantity: 1.046 },
    { height: 0.87, speed: 1.133674732, quantity: 1.047 },
    { height: 0.871, speed: 1.133363479, quantity: 1.048 },
    { height: 0.873, speed: 1.133029717, quantity: 1.049 },
    { height: 0.874, speed: 1.132688992, quantity: 1.050 },
    { height: 0.875, speed: 1.132327828, quantity: 1.051 },
    { height: 0.877, speed: 1.131955286, quantity: 1.052 },
    { height: 0.878, speed: 1.131561695, quantity: 1.053 },
    { height: 0.88, speed: 1.131154691, quantity: 1.054 },
    { height: 0.881, speed: 1.130722647, quantity: 1.055 },
    { height: 0.883, speed: 1.130278186, quantity: 1.056 },
    { height: 0.884, speed: 1.129800413, quantity: 1.057 },
    { height: 0.886, speed: 1.129312836, quantity: 1.058 },
    { height: 0.888, speed: 1.128782735, quantity: 1.059 },
    { height: 0.889, speed: 1.128234242, quantity: 1.060 },
    { height: 0.891, speed: 1.12765487, quantity: 1.061 },
    { height: 0.893, speed: 1.127032789, quantity: 1.062 },
    { height: 0.895, speed: 1.126381885, quantity: 1.063 },
    { height: 0.896, speed: 1.125687443, quantity: 1.064 },
    { height: 0.898, speed: 1.124934369, quantity: 1.065 },
    { height: 0.9, speed: 1.124131346, quantity: 1.066 },
    { height: 0.903, speed: 1.123267755, quantity: 1.067 },
    { height: 0.905, speed: 1.122329899, quantity: 1.068 },
    { height: 0.907, speed: 1.12129981, quantity: 1.069 },
    { height: 0.91, speed: 1.120153456, quantity: 1.070 },
    { height: 0.912, speed: 1.118857956, quantity: 1.071 },
    { height: 0.916, speed: 1.117367112, quantity: 1.072 },
    { height: 0.919, speed: 1.115613942, quantity: 1.073 },
    { height: 0.923, speed: 1.1133825, quantity: 1.074 },
    { height: 1, speed: 1, quantity: 1 },

]