import { userTypes } from '../actions'

const initialState = {
  contactDetails:
    null
  // [
  //   {name:''},
  //   {email:''},
  //   {phone:''},
  //   {company:''},
  // ]
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case userTypes.ADD_CONTACT_DETAILS:
      return { ...state, contactDetails: action.payload }
    default:
      return state
  }
}

export default user
