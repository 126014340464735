import React, { Component } from 'react'
import { connect } from 'react-redux'
import CalculationWallThicknessDesktop from './CalculationWallThicknessDesktop'
import CalculationWallThicknessMobile from './CalculationWallThicknessMobile'
import { withGetScreen } from 'react-getscreen'

class CalculationWallThickness extends Component {
  render() {
    if (this.props.isMobile()) {
      return <CalculationWallThicknessMobile />
    } else {
      return <CalculationWallThicknessDesktop />
    }
  }
}

const options = { mobileLimit: 900, shouldListenOnResize: true }
export default connect()(withGetScreen(CalculationWallThickness, options))
