export const coefficents = [
  {
    diameter: 'DN12',
    materials: [
      {
        name: 'PVC2',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN20', results: { min: 2, max: null } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE402',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN8', results: { min: 2, max: 2.3 } },
                { name: 'PN10', results: { min: 2.1, max: 2.7 } }
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN16',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN20', results: { min: 2, max: null } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN8', results: { min: 2, max: 2.3 } },
                { name: 'PN10', results: { min: 2.1, max: 2.7 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN16', results: { min: 2, max: 2.3 } },
                { name: 'PN20', results: { min: 2.3, max: 2.7 } },
                { name: 'PN25', results: { min: 3, max: 3.7 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN20', results: { min: 2, max: 2.3 } },
                { name: 'PN25', results: { min: 2.3, max: 2.7 } }
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN20',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN16', results: { min: 1.5, max: null } },
                { name: 'PN20', results: { min: 1.5, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN8', results: { min: 2, max: 2.3 } },
                { name: 'PN10', results: { min: 2.1, max: 2.7 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN10', results: { min: 2, max: 2.3 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN12.5', results: { min: 2, max: 2.3 } },
                { name: 'PN16', results: { min: 2.3, max: 2.7 } },
                { name: 'PN20', results: { min: 3, max: 3.4 } },
                { name: 'PN25', results: { min: 3.4, max: 3.9 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN16', results: { min: 2, max: 2.3 } },
                { name: 'PN20', results: { min: 2.3, max: 2.7 } },
                { name: 'PN25', results: { min: 3, max: 3.4 } }
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN25',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN12.5', results: { min: 1.5, max: null } },
                { name: 'PN16', results: { min: 1.9, max: null } },
                { name: 'PN20', results: { min: 2.3, max: null } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN5', results: { min: 2, max: 2.3 } },
                { name: 'PN8', results: { min: 3, max: 3.4 } },
                { name: 'PN10', results: { min: 2.5, max: 4 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN8', results: { min: 2, max: 2.3 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN10', results: { min: 2, max: 2.3 } },
                { name: 'PN12.5', results: { min: 2.3, max: 2.7 } },
                { name: 'PN16', results: { min: 3, max: 3.4 } },
                { name: 'PN20', results: { min: 2.5, max: 4 } },
                { name: 'PN25', results: { min: 4.2, max: 4.8 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN12.5', results: { min: 2, max: 2.3 } },
                { name: 'PN16', results: { min: 2.3, max: 2.7 } },
                { name: 'PN20', results: { min: 3, max: 3.4 } },
                { name: 'PN25', results: { min: 2.5, max: 4 } }
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN32',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN8', results: { min: 1.5, max: null } },
                { name: 'PN10', results: { min: 1.5, max: null } },
                { name: 'PN12.5', results: { min: 1.9, max: null } },
                { name: 'PN16', results: { min: 1.9, max: null } },
                { name: 'PN20', results: { min: 2.3, max: null } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN5', results: { min: 2, max: 2.3 } },
                { name: 'PN8', results: { min: 3, max: 3.4 } },
                { name: 'PN10', results: { min: 2.5, max: 4 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN8', results: { min: 2, max: 2.3 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN10', results: { min: 2, max: 2.3 } },
                { name: 'PN12.5', results: { min: 2.3, max: 2.7 } },
                { name: 'PN16', results: { min: 3, max: 3.4 } },
                { name: 'PN20', results: { min: 2.5, max: 4 } },
                { name: 'PN25', results: { min: 4.2, max: 4.8 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN12.5', results: { min: 2, max: 2.3 } },
                { name: 'PN16', results: { min: 2.3, max: 2.7 } },
                { name: 'PN20', results: { min: 3, max: 3.4 } },
                { name: 'PN25', results: { min: 2.5, max: 4 } }
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN40',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 1.5, max: null } },
                { name: 'PN8', results: { min: 1.6, max: null } },
                { name: 'PN10', results: { min: 1.9, max: null } },
                { name: 'PN12.5', results: { min: 2.4, max: null } },
                { name: 'PN16', results: { min: 3, max: null } },
                { name: 'PN20', results: { min: 3.7, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN3.2', results: { min: 2, max: 2.3 } },
                { name: 'PN4', results: { min: 2.4, max: 2.8 } },
                { name: 'PN5', results: { min: 3, max: 3.4 } },
                { name: 'PN8', results: { min: 3, max: 3.4 } },
                { name: 'PN10', results: { min: 2.5, max: 4 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN5', results: { min: 2, max: 2.3 } },
                { name: 'PN6', results: { min: 2.3, max: 2.7 } },
                { name: 'PN8', results: { min: 3, max: 3.5 } },
                { name: 'PN10', results: { min: 3.7, max: 4.2 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 2, max: 2.3 } },
                { name: 'PN8', results: { min: 2.4, max: 2.8 } },
                { name: 'PN10', results: { min: 3, max: 3.5 } },
                { name: 'PN12.5', results: { min: 3.7, max: 4.2 } },
                { name: 'PN16', results: { min: 4.5, max: 5.1 } },
                { name: 'PN20', results: { min: 5.5, max: 6.2 } },
                { name: 'PN25', results: { min: 6.7, max: 7.5 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN8', results: { min: 2, max: 2.3 } },
                { name: 'PN10', results: { min: 2.4, max: 2.8 } },
                { name: 'PN12.5', results: { min: 3, max: 3.5 } },
                { name: 'PN16', results: { min: 3.7, max: 4.2 } },
                { name: 'PN20', results: { min: 4.5, max: 5.1 } },
                { name: 'PN25', results: { min: 5.5, max: 6.2 } }
              ]
            }
          }]
        }
      },
    ]
  },

  {
    diameter: 'DN50',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 1.6, max: null } },
                { name: 'PN8', results: { min: 2, max: null } },
                { name: 'PN10', results: { min: 2.4, max: null } },
                { name: 'PN12.5', results: { min: 3, max: null } },
                { name: 'PN16', results: { min: 3.7, max: null } },
                { name: 'PN20', results: { min: 4.6, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 2, max: 2.3 } },
                { name: 'PN3.2', results: { min: 2.4, max: 2.8 } },
                { name: 'PN4', results: { min: 3, max: 3.4 } },
                { name: 'PN5', results: { min: 3.7, max: 4.2 } },
                { name: 'PN8', results: { min: 5.6, max: 6.3 } },
                { name: 'PN10', results: { min: 6.9, max: 7.7 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 2, max: 2.3 } },
                { name: 'PN5', results: { min: 2.4, max: 2.8 } },
                { name: 'PN6', results: { min: 2.9, max: 3.3 } },
                { name: 'PN8', results: { min: 3.7, max: 4.2 } },
                { name: 'PN10', results: { min: 4.6, max: 5.2 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN5', results: { min: 2, max: 2.3 } },
                { name: 'PN6', results: { min: 2.4, max: 2.8 } },
                { name: 'PN8', results: { min: 3, max: 3.4 } },
                { name: 'PN10', results: { min: 3.7, max: 4.2 } },
                { name: 'PN12.5', results: { min: 4.6, max: 5.2 } },
                { name: 'PN16', results: { min: 5.6, max: 6.3 } },
                { name: 'PN20', results: { min: 6.9, max: 7.7 } },
                { name: 'PN25', results: { min: 8.3, max: 9.3 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 2, max: 2.3 } },
                { name: 'PN8', results: { min: 2.4, max: 2.8 } },
                { name: 'PN10', results: { min: 3, max: 3.4 } },
                { name: 'PN12.5', results: { min: 3.7, max: 4.2 } },
                { name: 'PN16', results: { min: 4.6, max: 5.2 } },
                { name: 'PN20', results: { min: 5.6, max: 6.3 } },
                { name: 'PN25', results: { min: 6.9, max: 7.7 } }
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN63',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 2, max: null } },
                { name: 'PN8', results: { min: 2.5, max: null } },
                { name: 'PN10', results: { min: 3, max: null } },
                { name: 'PN12.5', results: { min: 3.8, max: null } },
                { name: 'PN16', results: { min: 4.7, max: null } },
                { name: 'PN20', results: { min: 5.8, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 2.5, max: 2.9 } },
                { name: 'PN3.2', results: { min: 3, max: 3.4 } },
                { name: 'PN4', results: { min: 3.8, max: 4.3 } },
                { name: 'PN5', results: { min: 4.7, max: 5.3 } },
                { name: 'PN8', results: { min: 7.1, max: 8 } },
                { name: 'PN10', results: { min: 8.6, max: 9.6 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 2.5, max: 2.9 } },
                { name: 'PN5', results: { min: 3, max: 3.4 } },
                { name: 'PN6', results: { min: 3.6, max: 4.1 } },
                { name: 'PN8', results: { min: 4.7, max: 5.3 } },
                { name: 'PN10', results: { min: 5.8, max: 6.5 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN5', results: { min: 2.5, max: 2.9 } },
                { name: 'PN6', results: { min: 3, max: 3.4 } },
                { name: 'PN8', results: { min: 3.8, max: 4.3 } },
                { name: 'PN10', results: { min: 4.7, max: 5.3 } },
                { name: 'PN12.5', results: { min: 5.8, max: 6.5 } },
                { name: 'PN16', results: { min: 7.1, max: 8 } },
                { name: 'PN20', results: { min: 8.6, max: 9.6 } },
                { name: 'PN25', results: { min: 10.5, max: 11.7 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 2.5, max: 2.9 } },
                { name: 'PN8', results: { min: 3, max: 3.4 } },
                { name: 'PN10', results: { min: 3.8, max: 4.3 } },
                { name: 'PN12.5', results: { min: 4.7, max: 5.3 } },
                { name: 'PN16', results: { min: 5.8, max: 6.5 } },
                { name: 'PN20', results: { min: 7.1, max: 8 } },
                { name: 'PN25', results: { min: 8.6, max: 9.6 } }
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN75',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 2.3, max: null } },
                { name: 'PN8', results: { min: 2.9, max: null } },
                { name: 'PN10', results: { min: 3.6, max: null } },
                { name: 'PN12.5', results: { min: 4.5, max: null } },
                { name: 'PN16', results: { min: 5.6, max: null } },
                { name: 'PN20', results: { min: 6.8, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 2.9, max: 3.3 } },
                { name: 'PN3.2', results: { min: 3.6, max: 4.1 } },
                { name: 'PN4', results: { min: 4.5, max: 5.1 } },
                { name: 'PN5', results: { min: 5.6, max: 6.3 } },
                { name: 'PN8', results: { min: 8.4, max: 9.4 } },
                { name: 'PN10', results: { min: 10.3, max: 11.5 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 2.9, max: 3.3 } },
                { name: 'PN5', results: { min: 3.6, max: 4.1 } },
                { name: 'PN6', results: { min: 4.3, max: 4.9 } },
                { name: 'PN8', results: { min: 5.6, max: 6.3 } },
                { name: 'PN10', results: { min: 6.8, max: 7.6 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN5', results: { min: 2.9, max: 3.3 } },
                { name: 'PN6', results: { min: 3.6, max: 4.1 } },
                { name: 'PN8', results: { min: 4.5, max: 5.1 } },
                { name: 'PN10', results: { min: 5.6, max: 6.3 } },
                { name: 'PN12.5', results: { min: 6.8, max: 7.6 } },
                { name: 'PN16', results: { min: 8.4, max: 9.4 } },
                { name: 'PN20', results: { min: 10.3, max: 11.5 } },
                { name: 'PN25', results: { min: 12.5, max: 13.9 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 2.9, max: 2.9 } },
                { name: 'PN8', results: { min: 3.6, max: 4.1 } },
                { name: 'PN10', results: { min: 4.5, max: 5.1 } },
                { name: 'PN12.5', results: { min: 5.6, max: 6.3 } },
                { name: 'PN16', results: { min: 6.8, max: 7.6 } },
                { name: 'PN20', results: { min: 8.4, max: 9.4 } },
                { name: 'PN25', results: { min: 10.3, max: 11.5 } }
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN90',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 2.8, max: null } },
                { name: 'PN8', results: { min: 3.5, max: null } },
                { name: 'PN10', results: { min: 4.3, max: null } },
                { name: 'PN12.5', results: { min: 5.4, max: null } },
                { name: 'PN16', results: { min: 6.7, max: null } },
                { name: 'PN20', results: { min: 8.2, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 3.5, max: 4 } },
                { name: 'PN3.2', results: { min: 4.3, max: 4.9 } },
                { name: 'PN4', results: { min: 5.4, max: 6.1 } },
                { name: 'PN5', results: { min: 6.7, max: 7.5 } },
                { name: 'PN8', results: { min: 10.1, max: 11.3 } },
                { name: 'PN10', results: { min: 12.3, max: 13.7 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 3.5, max: 4 } },
                { name: 'PN5', results: { min: 4.3, max: 4.9 } },
                { name: 'PN6', results: { min: 5.1, max: 5.8 } },
                { name: 'PN8', results: { min: 6.7, max: 7.5 } },
                { name: 'PN10', results: { min: 8.2, max: 9.2 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN5', results: { min: 3.5, max: 4 } },
                { name: 'PN6', results: { min: 4.3, max: 4.9 } },
                { name: 'PN8', results: { min: 5.4, max: 6.1 } },
                { name: 'PN10', results: { min: 6.7, max: 7.5 } },
                { name: 'PN12.5', results: { min: 8.2, max: 9.2 } },
                { name: 'PN16', results: { min: 10.1, max: 11.3 } },
                { name: 'PN20', results: { min: 12.3, max: 13.7 } },
                { name: 'PN25', results: { min: 15, max: 16.7 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 3.5, max: 4 } },
                { name: 'PN8', results: { min: 4.3, max: 4.9 } },
                { name: 'PN10', results: { min: 5.4, max: 6.1 } },
                { name: 'PN12.5', results: { min: 6.7, max: 7.5 } },
                { name: 'PN16', results: { min: 8.2, max: 9.2 } },
                { name: 'PN20', results: { min: 10.1, max: 11.3 } },
                { name: 'PN25', results: { min: 12.3, max: 13.7 } }
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN110',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 2.7, max: null } },
                { name: 'PN7.5', results: { min: 3.2, max: null } },
                { name: 'PN8', results: { min: 3.4, max: null } },
                { name: 'PN10', results: { min: 4.2, max: null } },
                { name: 'PN12.5', results: { min: 5.3, max: null } },
                { name: 'PN16', results: { min: 6.6, max: null } },
                { name: 'PN20', results: { min: 8.1, max: null } },
                { name: 'PN25', results: { min: 10, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PVC2',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 3.2, max: null } },
                { name: 'PN7.5', results: { min: 3.4, max: null } },
                { name: 'PN8', results: { min: 4.2, max: null } },
                { name: 'PN10', results: { min: 5.3, max: null } },
                { name: 'PN12.5', results: { min: 6.6, max: null } },
                { name: 'PN16', results: { min: 8.1, max: null } },
                { name: 'PN20', results: { min: 10, max: null } },


              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 4.2, max: 4.8 } },
                { name: 'PN3.2', results: { min: 5.3, max: 6 } },
                { name: 'PN4', results: { min: 6.6, max: 7.4 } },
                { name: 'PN5', results: { min: 8.1, max: 9.1 } },
                { name: 'PN8', results: { min: 12.3, max: 13.7 } },
                { name: 'PN10', results: { min: 15.1, max: 16.8 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 4.2, max: 4.8 } },
                { name: 'PN5', results: { min: 5.3, max: 6 } },
                { name: 'PN6', results: { min: 6.3, max: 7.1 } },
                { name: 'PN8', results: { min: 8.1, max: 9.1 } },
                { name: 'PN10', results: { min: 10, max: 11.1 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN5', results: { min: 4.2, max: 4.8 } },
                { name: 'PN6', results: { min: 5.3, max: 6 } },
                { name: 'PN8', results: { min: 6.6, max: 7.4 } },
                { name: 'PN10', results: { min: 8.1, max: 9.1 } },
                { name: 'PN12.5', results: { min: 10, max: 11.1 } },
                { name: 'PN16', results: { min: 12.3, max: 13.7 } },
                { name: 'PN20', results: { min: 15.1, max: 16.8 } },
                { name: 'PN25', results: { min: 18.3, max: 20.3 } }
              ]
            }
          }]
        }
      },

      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 4.2, max: 4.8 } },
                { name: 'PN8', results: { min: 5.3, max: 6 } },
                { name: 'PN10', results: { min: 6.6, max: 7.4 } },
                { name: 'PN12.5', results: { min: 8.1, max: 9.5 } },
                { name: 'PN16', results: { min: 10, max: 11.1 } },
                { name: 'PN20', results: { min: 12.3, max: 13.7 } },
                { name: 'PN25', results: { min: 15.1, max: 16.8 } }
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN125',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 3.1, max: null } },
                { name: 'PN7.5', results: { min: 3.7, max: null } },
                { name: 'PN8', results: { min: 3.9, max: null } },
                { name: 'PN10', results: { min: 4.8, max: null } },
                { name: 'PN12.5', results: { min: 6, max: null } },
                { name: 'PN16', results: { min: 7.4, max: null } },
                { name: 'PN20', results: { min: 9.2, max: null } },
                { name: 'PN25', results: { min: 11.4, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PVC2',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 3.7, max: null } },
                { name: 'PN7.5', results: { min: 3.9, max: null } },
                { name: 'PN8', results: { min: 4.8, max: null } },
                { name: 'PN10', results: { min: 6, max: null } },
                { name: 'PN12.5', results: { min: 7.4, max: null } },
                { name: 'PN16', results: { min: 9.2, max: null } },
                { name: 'PN20', results: { min: 11.4, max: null } },


              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 4.8, max: 5.4 } },
                { name: 'PN3.2', results: { min: 6, max: 6.7 } },
                { name: 'PN4', results: { min:7.4, max: 8.3 } },
                { name: 'PN5', results: { min: 9.2, max: 10.3 } },
                { name: 'PN8', results: { min: 14, max: 15.6 } },
                { name: 'PN10', results: { min: 17.1, max: 19 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 4.8, max: 5.4 } },
                { name: 'PN5', results: { min: 6, max: 6.7 } },
                { name: 'PN6', results: { min: 7.1, max: 8 } },
                { name: 'PN8', results: { min: 9.2, max: 10.3 } },
                { name: 'PN10', results: { min: 11.3, max: 12.7 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN5', results: { min: 4.8, max: 5.4 } },
                { name: 'PN6', results: { min: 6, max: 6.7 } },
                { name: 'PN8', results: { min: 7.4, max: 8.3 } },
                { name: 'PN10', results: { min: 9.2, max: 10.3 } },
                { name: 'PN12.5', results: { min: 11.4, max: 12.7 } },
                { name: 'PN16', results: { min: 14, max: 15.6 } },
                { name: 'PN20', results: { min: 17.1, max: 19 } },
                { name: 'PN25', results: { min: 20.8, max: 23 } }
              ]
            }
          }]
        }
      },

      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 4.8, max: 5.4 } },
                { name: 'PN8', results: { min: 6, max: 6.7 } },
                { name: 'PN10', results: { min: 7.4, max: 8.3 } },
                { name: 'PN12.5', results: { min: 9.2, max: 10.3 } },
                { name: 'PN16', results: { min: 11.4, max: 12.7 } },
                { name: 'PN20', results: { min: 14, max: 15.6 } },
                { name: 'PN25', results: { min: 17.1, max: 19 } }
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN140',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 3.5, max: null } },
                { name: 'PN7.5', results: { min: 4.1, max: null } },
                { name: 'PN8', results: { min: 4.3, max: null } },
                { name: 'PN10', results: { min: 5.4, max: null } },
                { name: 'PN12.5', results: { min: 6.7, max: null } },
                { name: 'PN16', results: { min: 8.3, max: null } },
                { name: 'PN20', results: { min: 10.3, max: null } },
                { name: 'PN25', results: { min: 12.7, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PVC2',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 4.1, max: null } },
                { name: 'PN7.5', results: { min: 4.3, max: null } },
                { name: 'PN8', results: { min: 5.4, max: null } },
                { name: 'PN10', results: { min: 6.7, max: null } },
                { name: 'PN12.5', results: { min: 8.3, max: null } },
                { name: 'PN16', results: { min:10.3, max: null } },
                { name: 'PN20', results: { min: 12.7, max: null } },


              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 5.4, max: 6.1 } },
                { name: 'PN3.2', results: { min: 6.7, max: 7.5 } },
                { name: 'PN4', results: { min: 8.3, max: 9.3 } },
                { name: 'PN5', results: { min: 10.3, max:11.5 } },
                { name: 'PN8', results: { min: 15.7, max: 17.4 } },
                { name: 'PN10', results: { min: 19.2, max: 21.3 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 5.4, max: 6.1 } },
                { name: 'PN5', results: { min: 6.7, max: 7.5 } },
                { name: 'PN6', results: { min: 8, max: 9 } },
                { name: 'PN8', results: { min: 10.3, max: 11.5 } },
                { name: 'PN10', results: { min: 12.7, max: 14.1 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN5', results: { min: 5.4, max: 6.1 } },
                { name: 'PN6', results: { min: 6.7, max: 7.5 } },
                { name: 'PN8', results: { min: 8.3, max: 3.3 } },
                { name: 'PN10', results: { min: 10.3, max: 11.5 } },
                { name: 'PN12.5', results: { min: 12.7, max: 13.1 } },
                { name: 'PN16', results: { min: 15.7, max: 17.4 } },
                { name: 'PN20', results: { min: 19.2, max: 21.3 } },
                { name: 'PN25', results: { min: 23.3, max: 25.8 } }
              ]
            }
          }]
        }
      },

      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 5.4, max: 6.1 } },
                { name: 'PN8', results: { min: 6.7, max: 7.5 } },
                { name: 'PN10', results: { min: 8.3, max: 9.3 } },
                { name: 'PN12.5', results: { min: 10.3, max: 11.5 } },
                { name: 'PN16', results: { min: 12.7, max: 14.1 } },
                { name: 'PN20', results: { min: 15.7, max: 17.4 } },
                { name: 'PN25', results: { min: 19.2, max: 21.3 } }
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN160',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 4, max: null } },
                { name: 'PN7.5', results: { min: 4.7, max: null } },
                { name: 'PN8', results: { min: 4.9, max: null } },
                { name: 'PN10', results: { min: 6.2, max: null } },
                { name: 'PN12.5', results: { min: 7.7, max: null } },
                { name: 'PN16', results: { min: 9.5, max: null } },
                { name: 'PN20', results: { min: 11.8, max: null } },
                { name: 'PN25', results: { min: 14.6, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PVC2',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 4.7, max: null } },
                { name: 'PN7.5', results: { min: 4.9, max: null } },
                { name: 'PN8', results: { min: 6.2, max: null } },
                { name: 'PN10', results: { min: 7.7, max: null } },
                { name: 'PN12.5', results: { min: 9.5, max: null } },
                { name: 'PN16', results: { min: 11.8, max: null } },
                { name: 'PN20', results: { min: 14.6, max: null } },


              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 6.2, max: 7 } },
                { name: 'PN3.2', results: { min: 7.7, max: 8.6 } },
                { name: 'PN4', results: { min: 9.5, max: 10.6 } },
                { name: 'PN5', results: { min: 11.8, max: 13.1 } },
                { name: 'PN8', results: { min: 17.9, max: 19.8 } },
                { name: 'PN10', results: { min: 21.9, max: 24.2 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 6.2, max: 7 } },
                { name: 'PN5', results: { min: 7.7, max: 8.6 } },
                { name: 'PN6', results: { min: 9.1, max: 10.2 } },
                { name: 'PN8', results: { min: 11.8, max: 13.1 } },
                { name: 'PN10', results: { min: 14.6, max: 16.2 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN5', results: { min: 6.2, max: 7 } },
                { name: 'PN6', results: { min: 7.7, max: 8.6 } },
                { name: 'PN8', results: { min: 9.5, max: 10.6 } },
                { name: 'PN10', results: { min: 11.8, max: 13.1 } },
                { name: 'PN12.5', results: { min: 14.6, max: 16.2 } },
                { name: 'PN16', results: { min: 17.9, max: 19.8 } },
                { name: 'PN20', results: { min: 21.9, max: 24.2 } },
                { name: 'PN25', results: { min: 26.6, max: 29.4 } }
              ]
            }
          }]
        }
      },

      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 6.2, max: 7 } },
                { name: 'PN8', results: { min: 7.7, max: 8.6 } },
                { name: 'PN10', results: { min: 9.5, max: 10.6 } },
                { name: 'PN12.5', results: { min: 11.8, max: 13.1 } },
                { name: 'PN16', results: { min: 14.6, max: 16.2 } },
                { name: 'PN20', results: { min: 17.9, max: 19.8 } },
                { name: 'PN25', results: { min: 21.9, max: 24.2 } }
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN180',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 4.4, max: null } },
                { name: 'PN7.5', results: { min: 5.4, max: null } },
                { name: 'PN8', results: { min: 5.5, max: null } },
                { name: 'PN10', results: { min: 6.9, max: null } },
                { name: 'PN12.5', results: { min: 8.6, max: null } },
                { name: 'PN16', results: { min: 10.7, max: null } },
                { name: 'PN20', results: { min: 13.3, max: null } },
                { name: 'PN25', results: { min: 16.4, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PVC2',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 5.3, max: null } },
                { name: 'PN7.5', results: { min: 5.5, max: null } },
                { name: 'PN8', results: { min: 6.9, max: null } },
                { name: 'PN10', results: { min: 8.6, max: null } },
                { name: 'PN12.5', results: { min: 10.7, max: null } },
                { name: 'PN16', results: { min:13.3, max: null } },
                { name: 'PN20', results: { min: 16.4, max: null } },


              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 6.9, max: 7.7 } },
                { name: 'PN3.2', results: { min: 8.6, max: 9.6 } },
                { name: 'PN4', results: { min: 10.7, max: 11.9 } },
                { name: 'PN5', results: { min: 13.3, max:14.8 } },
                { name: 'PN8', results: { min: 20.1, max: 22.3 } },
                { name: 'PN10', results: { min: 24.6, max: 27.2 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 6.9, max: 7.7 } },
                { name: 'PN5', results: { min: 8.6, max: 9.6 } },
                { name: 'PN6', results: { min: 10.2, max: 11.4 } },
                { name: 'PN8', results: { min: 13.3, max: 14.8 } },
                { name: 'PN10', results: { min: 16.4, max: 18.2 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN5', results: { min: 6.9 , max: 7.7 } },
                { name: 'PN6', results: { min: 8.6, max: 9.6 } },
                { name: 'PN8', results: { min: 10.7, max: 11.9 } },
                { name: 'PN10', results: { min: 13.3, max: 14.8 } },
                { name: 'PN12.5', results: { min: 16.4, max: 18.2 } },
                { name: 'PN16', results: { min: 20.1, max: 22.3 } },
                { name: 'PN20', results: { min: 24.6, max: 27.2 } },
                { name: 'PN25', results: { min: 29.2, max: 33 } }
              ]
            }
          }]
        }
      },

      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min:  6.9, max: 7.7 } },
                { name: 'PN8', results: { min: 8.6, max: 9.6 } },
                { name: 'PN10', results: { min: 10.7, max: 11.9 } },
                { name: 'PN12.5', results: { min: 13.3, max: 14.8 } },
                { name: 'PN16', results: { min: 16.4, max: 18.2 } },
                { name: 'PN20', results: { min: 20.1, max: 22.3 } },
                { name: 'PN25', results: { min: 24.6, max: 27.2 } }
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN200',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 4.9, max: null } },
                { name: 'PN7.5', results: { min: 5.9, max: null } },
                { name: 'PN8', results: { min: 6.2, max: null } },
                { name: 'PN10', results: { min: 7.7, max: null } },
                { name: 'PN12.5', results: { min: 9.6, max: null } },
                { name: 'PN16', results: { min: 11.9, max: null } },
                { name: 'PN20', results: { min: 14.7, max: null } },
                { name: 'PN25', results: { min: 18.2, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PVC2',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 5.9, max: null } },
                { name: 'PN7.5', results: { min: 6.2, max: null } },
                { name: 'PN8', results: { min: 7.7, max: null } },
                { name: 'PN10', results: { min: 9.6, max: null } },
                { name: 'PN12.5', results: { min: 11.9, max: null } },
                { name: 'PN16', results: { min:14.7, max: null } },
                { name: 'PN20', results: { min: 18.2, max: null } },


              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 7.7, max: 8.6 } },
                { name: 'PN3.2', results: { min: 9.6, max: 10.7 } },
                { name: 'PN4', results: { min: 11.9, max: 13.2 } },
                { name: 'PN5', results: { min: 14.7, max:16.3 } },
                { name: 'PN8', results: { min: 22.4, max: 30.3 } },
                { name: 'PN10', results: { min: 27.4, max: 30.3 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 7.7, max: 8.6 } },
                { name: 'PN5', results: { min: 9.6, max: 10.7 } },
                { name: 'PN6', results: { min: 11.4, max: 12.7 } },
                { name: 'PN8', results: { min: 14.7, max: 16.3 } },
                { name: 'PN10', results: { min: 18.2, max: 20.2 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN5', results: { min: 7.7 , max: 8.6 } },
                { name: 'PN6', results: { min: 9.6, max: 10.7 } },
                { name: 'PN8', results: { min: 11.9, max: 13.2 } },
                { name: 'PN10', results: { min: 14.7, max: 16.3 } },
                { name: 'PN12.5', results: { min: 18.2, max: 20.2 } },
                { name: 'PN16', results: { min: 22.4, max: 24.8 } },
                { name: 'PN20', results: { min: 27.4, max: 30.3 } },
                { name: 'PN25', results: { min: 33.2, max: 36.7 } }
              ]
            }
          }]
        }
      },

      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min:  7.7, max: 8.6 } },
                { name: 'PN8', results: { min: 9.6, max: 10.7 } },
                { name: 'PN10', results: { min: 11.9, max: 13.2 } },
                { name: 'PN12.5', results: { min: 14.7, max: 16.3 } },
                { name: 'PN16', results: { min: 18.2, max: 20.2 } },
                { name: 'PN20', results: { min: 22.4, max: 24.8 } },
                { name: 'PN25', results: { min: 27.4, max: 30.3 } }
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN225',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 5.5, max: null } },
                { name: 'PN7.5', results: { min: 6.6, max: null } },
                { name: 'PN8', results: { min: 6.9, max: null } },
                { name: 'PN10', results: { min: 8.6, max: null } },
                { name: 'PN12.5', results: { min: 10.8, max: null } },
                { name: 'PN16', results: { min: 13.4, max: null } },
                { name: 'PN20', results: { min: 16.6, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PVC2',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 6.6, max: null } },
                { name: 'PN7.5', results: { min: 6.9, max: null } },
                { name: 'PN8', results: { min: 8.6, max: null } },
                { name: 'PN10', results: { min: 10.8, max: null } },
                { name: 'PN12.5', results: { min: 13.4, max: null } },
                { name: 'PN16', results: { min:16.6, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 8.6, max: 9.6 } },
                { name: 'PN3.2', results: { min: 10.8, max: 12 } },
                { name: 'PN4', results: { min: 13.4, max: 14.9 } },
                { name: 'PN5', results: { min: 16.6, max:18.4 } },
                { name: 'PN8', results: { min: 25.2, max: 27.9 } },
                { name: 'PN10', results: { min: 30.8, max: 34 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 8.6, max: 9.6 } },
                { name: 'PN5', results: { min: 10.8, max: 12 } },
                { name: 'PN6', results: { min: 12.8, max: 14.2 } },
                { name: 'PN8', results: { min: 16.6, max: 18.4 } },
                { name: 'PN10', results: { min: 20.5, max: 22.7 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN5', results: { min: 8.6 , max: 9.6 } },
                { name: 'PN6', results: { min: 10.8, max: 12 } },
                { name: 'PN8', results: { min: 13.4, max: 14.9 } },
                { name: 'PN10', results: { min: 16.8, max: 18.4 } },
                { name: 'PN12.5', results: { min: 20.5, max: 22.7 } },
                { name: 'PN16', results: { min: 25.2, max: 27.9 } },
                { name: 'PN20', results: { min: 30.8, max: 34 } },
                { name: 'PN25', results: { min: 37.4, max: 41.3 } }
              ]
            }
          }]
        }
      },

      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min:  8.6, max: 9.6 } },
                { name: 'PN8', results: { min: 10.7, max: 12 } },
                { name: 'PN10', results: { min: 13.4, max: 14.9 } },
                { name: 'PN12.5', results: { min: 16.6, max: 18.4 } },
                { name: 'PN16', results: { min: 20.5, max: 22.7 } },
                { name: 'PN20', results: { min: 25.2, max: 27.9 } },
                { name: 'PN25', results: { min: 30.8, max: 34 } }
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN250',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 6.2, max: null } },
                { name: 'PN7.5', results: { min: 7.3, max: null } },
                { name: 'PN8', results: { min: 7.7, max: null } },
                { name: 'PN10', results: { min: 9.6, max: null } },
                { name: 'PN12.5', results: { min: 11.9, max: null } },
                { name: 'PN16', results: { min: 14.8, max: null } },
                { name: 'PN20', results: { min: 18.4, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PVC2',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 7.3, max: null } },
                { name: 'PN7.5', results: { min: 7.7, max: null } },
                { name: 'PN8', results: { min: 9.6, max: null } },
                { name: 'PN10', results: { min: 11.9, max: null } },
                { name: 'PN12.5', results: { min: 14.8, max: null } },
                { name: 'PN16', results: { min:18.4, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 9.6, max: 10.7 } },
                { name: 'PN3.2', results: { min: 11.9, max: 13.2 } },
                { name: 'PN4', results: { min: 14.8, max: 16.4 } },
                { name: 'PN5', results: { min: 18.4, max:20.4 } },
                { name: 'PN8', results: { min: 27.9, max: 30.8 } },
                { name: 'PN10', results: { min: 34.2, max: 37.8 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 9.6, max: 10.7 } },
                { name: 'PN5', results: { min: 11.9, max: 13.2 } },
                { name: 'PN6', results: { min: 14.2, max: 15.8 } },
                { name: 'PN8', results: { min: 18.4, max: 20.4 } },
                { name: 'PN10', results: { min: 22.7, max: 25.1 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN5', results: { min: 9.6 , max: 10.7 } },
                { name: 'PN6', results: { min: 11.9, max: 13.2 } },
                { name: 'PN8', results: { min: 14.8, max: 16.4 } },
                { name: 'PN10', results: { min: 18.4, max: 20.4 } },
                { name: 'PN12.5', results: { min: 22.7, max: 25.1 } },
                { name: 'PN16', results: { min: 27.9, max: 30.8 } },
                { name: 'PN20', results: { min: 34.2, max: 37.8 } },
                { name: 'PN25', results: { min: 41.5, max: 45.8 } }
              ]
            }
          }]
        }
      },

      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min:  9.6, max: 10.7 } },
                { name: 'PN8', results: { min: 11.9, max: 13.2 } },
                { name: 'PN10', results: { min: 14.8, max: 16.4 } },
                { name: 'PN12.5', results: { min: 18.4, max: 20.4 } },
                { name: 'PN16', results: { min: 22.7, max: 25.1 } },
                { name: 'PN20', results: { min: 27.9, max: 30.8 } },
                { name: 'PN25', results: { min: 34.2, max: 37.8 } },
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN280',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 6.9, max: null } },
                { name: 'PN7.5', results: { min: 8.2, max: null } },
                { name: 'PN8', results: { min: 8.6, max: null } },
                { name: 'PN10', results: { min: 10.7, max: null } },
                { name: 'PN12.5', results: { min: 13.4, max: null } },
                { name: 'PN16', results: { min: 16.6, max: null } },
                { name: 'PN20', results: { min: 20.6, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PVC2',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 8.2, max: null } },
                { name: 'PN7.5', results: { min: 8.6, max: null } },
                { name: 'PN18', results: { min: 10.7, max: null } },
                { name: 'PN10', results: { min: 13.4, max: null } },
                { name: 'PN12.5', results: { min: 16.6, max: null } },
                { name: 'PN20', results: { min: 20.6, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 10.7, max: 11.9 } },
                { name: 'PN3.2', results: { min: 13.4, max: 14.9 } },
                { name: 'PN4', results: { min: 16.6, max: 18.4 } },
                { name: 'PN5', results: { min: 20.6, max:22.8 } },
                { name: 'PN8', results: { min: 31.3, max: 34.6 } },
                { name: 'PN10', results: { min: 38.3, max: 42.3 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 10.7, max: 11.9 } },
                { name: 'PN5', results: { min: 13.4, max: 14.9 } },
                { name: 'PN6', results: { min: 15.9, max: 17.6 } },
                { name: 'PN8', results: { min: 20.6, max: 22.8 } },
                { name: 'PN10', results: { min: 25.4, max: 28.1 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN5', results: { min: 10.7, max: 11.9 } },
                { name: 'PN6', results: { min: 13.4, max: 14.9 } },
                { name: 'PN8', results: { min: 15.9, max: 17.6 } },
                { name: 'PN10', results: { min: 20.6, max: 22.8 } },
                { name: 'PN12.5', results: { min: 25.4, max: 28.1 } },
                { name: 'PN16', results: { min: 31.3, max: 34.6 } },
                { name: 'PN20', results: { min: 38.3, max: 42.3 } },
                { name: 'PN25', results: { min: 46.5, max: 51.3 } }
              ]
            }
          }]
        }
      },

      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min:  10.7, max: 11.9 } },
                { name: 'PN8', results: { min: 13.4, max: 14.9 } },
                { name: 'PN10', results: { min: 16.8, max: 18.4 } },
                { name: 'PN12.5', results: { min: 20.6, max: 22.8 } },
                { name: 'PN16', results: { min: 25.4, max: 28.1 } },
                { name: 'PN20', results: { min: 31.3, max: 34.6 } },
                { name: 'PN25', results: { min: 38.3, max: 42.3 } },
              ]
            }
          }]
        }
      },
    ]
  },
  
  {
    diameter: 'DN315',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 7.7, max: null } },
                { name: 'PN7.5', results: { min: 9.2, max: null } },
                { name: 'PN8', results: { min: 9.7, max: null } },
                { name: 'PN10', results: { min: 12.1, max: null } },
                { name: 'PN12.5', results: { min: 15, max: null } },
                { name: 'PN16', results: { min: 18.7, max: null } },
                { name: 'PN20', results: { min: 23.2, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PVC2',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 9.2, max: null } },
                { name: 'PN7.5', results: { min: 9.7, max: null } },
                { name: 'PN8', results: { min: 12.1, max: null } },
                { name: 'PN10', results: { min: 15, max: null } },
                { name: 'PN12.5', results: { min: 18.7, max: null } },
                { name: 'PN16', results: { min: 23.2, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 12.1, max: 13.5 } },
                { name: 'PN3.2', results: { min: 15, max: 16.6 } },
                { name: 'PN4', results: { min: 18.7, max: 20.7 } },
                { name: 'PN5', results: { min: 23.2, max:25.7 } },
                { name: 'PN8', results: { min: 35.2, max: 38.9 } },
                { name: 'PN10', results: { min: 43.1, max: 47.6 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 7.7, max: 8.6 } },
                { name: 'PN3.2', results: { min: 9.7, max: 10.8 } },
                { name: 'PN4', results: { min: 12.1, max: 13.5 } },
                { name: 'PN5', results: { min: 15, max: 16.6 } },
                { name: 'PN6', results: { min: 17.9, max: 19.8 } },
                { name: 'PN8', results: { min: 23.2, max: 25.7 } },
                { name: 'PN10', results: { min: 28.6, max: 31.6 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN3.2', results: { min: 7.7, max: 8.6 } },
                { name: 'PN4', results: { min: 9.7, max: 10.8 } },
                { name: 'PN5', results: { min: 12.1, max: 13.5 } },
                { name: 'PN6', results: { min: 15, max: 16.6 } },
                { name: 'PN8', results: { min: 18.7, max: 20.7 } },
                { name: 'PN10', results: { min: 23.2, max: 25.7 } },
                { name: 'PN12.5', results: { min: 28.6, max: 31.6 } },
                { name: 'PN16', results: { min: 35.2, max: 38.9 } },
                { name: 'PN20', results: { min: 43.1, max: 47.6 } },
                { name: 'PN25', results: { min: 52.3, max: 57.6 } }
              ]
            }
          }]
        }
      },

      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 7.7, max: 8.6 } },
                { name: 'PN5', results: { min: 9.7, max: 10.8 } },
                { name: 'PN6', results: { min: 12.1, max: 13.5 } },
                { name: 'PN8', results: { min: 15, max: 16.6 } },
                { name: 'PN10', results: { min: 18.7, max: 20.7 } },
                { name: 'PN12.5', results: { min: 23.2, max: 25.7 } },
                { name: 'PN16', results: { min: 28.6, max: 31.6 } },
                { name: 'PN20', results: { min: 35.2, max: 38.9 } },
                { name: 'PN25', results: { min: 43.1, max: 47.6 } },
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN355',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 8.7, max: null } },
                { name: 'PN7.5', results: { min: 10.4, max: null } },
                { name: 'PN8', results: { min: 10.9, max: null } },
                { name: 'PN10', results: { min: 13.6, max: null } },
                { name: 'PN12.5', results: { min: 16.9, max: null } },
                { name: 'PN16', results: { min: 21.1, max: null } },
                { name: 'PN20', results: { min: 26.1, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PVC2',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 10.4, max: null } },
                { name: 'PN7.5', results: { min: 10.9, max: null } },
                { name: 'PN18', results: { min: 13.6, max: null } },
                { name: 'PN10', results: { min: 16.9, max: null } },
                { name: 'PN12.5', results: { min: 21.1, max: null } },
                { name: 'PN16', results: { min: 26.1, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 13.6, max: 15.1 } },
                { name: 'PN3.2', results: { min: 16.9, max: 18.7 } },
                { name: 'PN4', results: { min: 21.1, max: 23.4 } },
                { name: 'PN5', results: { min: 26.1, max: 28.9 } },
                { name: 'PN8', results: { min: 39.7, max: 43.8 } },
                { name: 'PN10', results: { min: 48.5, max: 53.5 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 8.7, max: 9.7 } },
                { name: 'PN3.2', results: { min: 10.9, max: 12.1 } },
                { name: 'PN4', results: { min: 13.6, max: 15.1 } },
                { name: 'PN5', results: { min: 16.9, max: 18.7 } },
                { name: 'PN6', results: { min: 20.1, max: 22.3 } },
                { name: 'PN8', results: { min: 26.1, max: 28.9 } },
                { name: 'PN10', results: { min: 32.2, max: 35.6 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN3.2', results: { min: 8.7, max: 9.7 } },
                { name: 'PN4', results: { min: 10.9, max: 12.1 } },
                { name: 'PN5', results: { min: 13.6, max: 15.1 } },
                { name: 'PN6', results: { min: 16.9, max: 18.7 } },
                { name: 'PN8', results: { min: 21.1, max: 23.4 } },
                { name: 'PN10', results: { min: 26.1, max: 28.9 } },
                { name: 'PN12.5', results: { min: 32.2, max: 35.6 } },
                { name: 'PN16', results: { min: 39.7, max: 43.8 } },
                { name: 'PN20', results: { min: 48.5, max: 53.5 } },
                { name: 'PN25', results: { min: 59, max: 65 } }
              ]
            }
          }]
        }
      },

      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 8.7, max: 9.7 } },
                { name: 'PN5', results: { min: 10.9, max: 12.1 } },
                { name: 'PN6', results: { min: 13.6, max: 15.1 } },
                { name: 'PN8', results: { min: 16.9, max: 18.7 } },
                { name: 'PN10', results: { min: 21.1, max: 23.4 } },
                { name: 'PN12.5', results: { min: 26.1, max: 28.9 } },
                { name: 'PN16', results: { min: 32.2, max: 35.6 } },
                { name: 'PN20', results: { min: 39.7, max: 43.8 } },
                { name: 'PN25', results: { min: 48.5, max: 53.5 } },
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN400',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 9.8, max: null } },
                { name: 'PN7.5', results: { min: 11.7, max: null } },
                { name: 'PN8', results: { min: 12.3, max: null } },
                { name: 'PN10', results: { min: 15.3, max: null } },
                { name: 'PN12.5', results: { min: 19.1, max: null } },
                { name: 'PN16', results: { min: 23.7, max: null } },
                { name: 'PN20', results: { min: 29.4, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PVC2',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 11.7, max: null } },
                { name: 'PN7.5', results: { min: 12.3, max: null } },
                { name: 'PN8', results: { min: 15.3, max: null } },
                { name: 'PN10', results: { min: 19.1, max: null } },
                { name: 'PN12.5', results: { min: 23.7, max: null } },
                { name: 'PN16', results: { min: 29.4, max: null } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 15.3, max: 17 } },
                { name: 'PN3.2', results: { min: 19.1, max: 21.2 } },
                { name: 'PN4', results: { min: 23.7, max: 26.2 } },
                { name: 'PN5', results: { min: 29.4, max: 32.5 } },
                { name: 'PN8', results: { min: 44.7, max: 49.3 } },
                { name: 'PN10', results: { min: 54.7, max: 60.3 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 9.8, max: 10.9 } },
                { name: 'PN3.2', results: { min: 12.3, max: 13.7 } },
                { name: 'PN4', results: { min: 15.3, max: 17 } },
                { name: 'PN5', results: { min: 19.1, max: 21.2 } },
                { name: 'PN6', results: { min: 22.7, max: 25.1 } },
                { name: 'PN8', results: { min: 29.4, max: 32.5 } },
                { name: 'PN10', results: { min: 36.3, max: 40.1 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN3.2', results: { min: 9.8, max: 10.9 } },
                { name: 'PN4', results: { min: 12.3, max: 13.7 } },
                { name: 'PN5', results: { min: 15.3, max: 17 } },
                { name: 'PN6', results: { min: 19.1, max: 21.2 } },
                { name: 'PN8', results: { min: 23.7, max: 26.2 } },
                { name: 'PN10', results: { min: 29.4, max: 32.5 } },
                { name: 'PN12.5', results: { min: 36.3, max: 40.1 } },
                { name: 'PN16', results: { min: 44.7, max: 49.3 } },
                { name: 'PN20', results: { min: 54.7, max: 60.3 } },
              ]
            }
          }]
        }
      },

      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 9.8, max: 10.9 } },
                { name: 'PN5', results: { min: 12.3, max: 13.7 } },
                { name: 'PN6', results: { min: 15.3, max: 17 } },
                { name: 'PN8', results: { min: 19.1, max: 21.2 } },
                { name: 'PN10', results: { min: 23.7, max: 26.2 } },
                { name: 'PN12.5', results: { min: 29.4, max: 32.5 } },
                { name: 'PN16', results: { min: 36.3, max: 40.1 } },
                { name: 'PN20', results: { min: 44.7, max: 49.3 } },
                { name: 'PN25', results: { min: 54.7, max: 60.3 } },
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN450',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 11, max: null } },
                { name: 'PN7.5', results: { min: 13.2, max: null } },
                { name: 'PN8', results: { min: 13.8, max: null } },
                { name: 'PN10', results: { min: 17.2, max: null } },
                { name: 'PN12.5', results: { min: 21.5, max: null } },
                { name: 'PN16', results: { min: 26.7, max: null } },
                { name: 'PN20', results: { min: 33.1, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PVC2',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 13.2, max: null } },
                { name: 'PN7.5', results: { min: 13.8, max: null } },
                { name: 'PN8', results: { min: 17.2, max: null } },
                { name: 'PN10', results: { min: 21.5, max: null } },
                { name: 'PN12.5', results: { min: 26.7, max: null } },
                { name: 'PN16', results: { min: 33.1, max: null } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 17.2, max: 19.1 } },
                { name: 'PN3.2', results: { min: 21.5, max: 23.8 } },
                { name: 'PN4', results: { min: 26.7, max: 29.5 } },
                { name: 'PN5', results: { min: 33.1, max: 36.6 } },
                { name: 'PN8', results: { min: 50.3, max: 55.5 } },
                { name: 'PN10', results: { min: 61.5, max: 67.8 } }
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 11, max: 12.2 } },
                { name: 'PN3.2', results: { min: 13.8, max: 15.3 } },
                { name: 'PN4', results: { min: 17.2, max: 19.1 } },
                { name: 'PN5', results: { min: 21.5, max: 23.8 } },
                { name: 'PN6', results: { min: 25.5, max: 28.2} },
                { name: 'PN8', results: { min: 33.1, max: 36.6 } },
                { name: 'PN10', results: { min: 40.9, max: 45.1 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN3.2', results: { min: 11, max: 12.2 } },
                { name: 'PN4', results: { min: 13.8, max: 15.3 } },
                { name: 'PN5', results: { min: 17.2, max: 19.1 } },
                { name: 'PN6', results: { min: 21.5, max: 23.8 } },
                { name: 'PN8', results: { min: 25.5, max: 28.2} },
                { name: 'PN10', results: { min: 33.1, max: 36.6 } },
                { name: 'PN12.5', results: { min: 40.9, max: 45.1 } },
                { name: 'PN16', results: { min: 50.3, max: 55.5 } },
                { name: 'PN20', results: { min: 61.5, max: 67.8 } },
              ]
            }
          }]
        }
      },

      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 11, max: 12.2 } },
                { name: 'PN5', results: { min: 13.8, max: 15.3 } },
                { name: 'PN6', results: { min: 17.2, max: 19.1 } },
                { name: 'PN8', results: { min: 21.5, max: 23.8 } },
                { name: 'PN10', results: { min: 25.5, max: 28.2} },
                { name: 'PN12.5', results: { min: 33.1, max: 36.6 } },
                { name: 'PN16', results: { min: 40.9, max: 45.1 } },
                { name: 'PN20', results: { min: 50.3, max: 55.5 } },
                { name: 'PN25', results: { min: 61.5, max: 67.8 } },
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN500',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 12.3, max: null } },
                { name: 'PN7.5', results: { min: 14.6, max: null } },
                { name: 'PN8', results: { min: 15.3, max: null } },
                { name: 'PN10', results: { min: 19.1, max: null } },
                { name: 'PN12.5', results: { min: 23.9, max: null } },
                { name: 'PN16', results: { min: 29.7, max: null } },
                { name: 'PN20', results: { min: 36.8, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PVC2',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 14.6, max: null } },
                { name: 'PN7.5', results: { min: 15.3, max: null } },
                { name: 'PN8', results: { min: 19.1, max: null } },
                { name: 'PN10', results: { min: 23.9, max: null } },
                { name: 'PN12.5', results: { min: 29.7, max: null } },
                { name: 'PN16', results: { min: 36.8, max: null } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 19.1, max: 21.2 } },
                { name: 'PN3.2', results: { min: 23.9, max: 26.4 } },
                { name: 'PN4', results: { min: 29.7, max: 32.8 } },
                { name: 'PN5', results: { min: 36.8, max: 40.6 } },
                { name: 'PN8', results: { min: 55.8, max: 61.5 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 12.3, max: 13.7 } },
                { name: 'PN3.2', results: { min: 15.3, max: 17 } },
                { name: 'PN4', results: { min: 19.1, max: 21.2 } },
                { name: 'PN5', results: { min: 23.9, max: 26.4 } },
                { name: 'PN6', results: { min: 28.3, max: 31.3} },
                { name: 'PN8', results: { min: 36.8, max: 40.6 } },
                { name: 'PN10', results: { min: 45.4, max: 50.1 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN3.2', results: { min: 12.3, max: 13.7 } },
                { name: 'PN4', results: { min: 15.3, max: 17 } },
                { name: 'PN5', results: { min: 19.1, max: 21.2 } },
                { name: 'PN6', results: { min: 23.9, max: 26.4 } },
                { name: 'PN8', results: { min: 29.7, max: 32.8} },
                { name: 'PN10', results: { min: 36.8, max: 40.6 } },
                { name: 'PN12.5', results: { min: 45.4, max: 50.1 } },
                { name: 'PN16', results: { min: 55.8, max: 61.5 } },
              ]
            }
          }]
        }
      },

      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 12.3, max: 13.7 } },
                { name: 'PN5', results: { min: 15.3, max: 17 } },
                { name: 'PN6', results: { min: 19.1, max: 21.2 } },
                { name: 'PN8', results: { min: 23.9, max: 26.4 } },
                { name: 'PN10', results: { min: 29.7, max: 32.8} },
                { name: 'PN12.5', results: { min: 36.8, max: 40.6 } },
                { name: 'PN16', results: { min: 45.4, max: 50.1 } },
                { name: 'PN20', results: { min: 55.8, max: 61.5 } },
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN560',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 13.7, max: null } },
                { name: 'PN7.5', results: { min: 16.4, max: null } },
                { name: 'PN8', results: { min: 17.2, max: null } },
                { name: 'PN10', results: { min: 21.4, max: null } },
                { name: 'PN12.5', results: { min: 26.7, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PVC2',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 16.4, max: null } },
                { name: 'PN7.5', results: { min: 17.2, max: null } },
                { name: 'PN8', results: { min: 21.4, max: null } },
                { name: 'PN10', results: { min: 26.7, max: null } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 21.4, max: 23.7 } },
                { name: 'PN3.2', results: { min: 26.7, max: 29.5 } },
                { name: 'PN4', results: { min: 33.2, max: 36.7 } },
                { name: 'PN5', results: { min: 41.2, max: 45.5 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 13.7, max: 15.2 } },
                { name: 'PN3.2', results: { min: 17.2, max: 19.1 } },
                { name: 'PN4', results: { min: 21.4, max: 23.7 } },
                { name: 'PN5', results: { min: 26.7, max: 29.5 } },
                { name: 'PN6', results: { min: 31.7, max: 35} },
                { name: 'PN8', results: { min: 41.2, max: 45.5 } },
                { name: 'PN10', results: { min: 50.8, max: 56 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN3.2', results: { min: 13.7, max: 15.2 } },
                { name: 'PN4', results: { min: 17.2, max: 19.1 } },
                { name: 'PN5', results: { min: 21.4, max: 23.7 } },
                { name: 'PN6', results: { min: 26.7, max: 29.5 } },
                { name: 'PN8', results: { min: 32.2, max: 36.7 } },
                { name: 'PN10', results: { min: 41.2, max: 45.5 } },
                { name: 'PN12.5', results: { min: 50.8, max: 56 } },
              ]
            }
          }]
        }
      },

      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 13.7, max: 15.2 } },
                { name: 'PN5', results: { min: 17.2, max: 19.1 } },
                { name: 'PN6', results: { min: 21.4, max: 23.7 } },
                { name: 'PN8', results: { min: 26.7, max: 29.5 } },
                { name: 'PN10', results: { min: 32.2, max: 36.7 } },
                { name: 'PN12.5', results: { min: 41.2, max: 45.5 } },
                { name: 'PN16', results: { min: 50.8, max: 56 } },
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN630',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 15.4, max: null } },
                { name: 'PN7.5', results: { min: 18.4, max: null } },
                { name: 'PN8', results: { min: 19.3, max: null } },
                { name: 'PN10', results: { min: 21.4, max: null } },
                { name: 'PN12.5', results: { min: 30, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PVC2',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 18.4, max: null } },
                { name: 'PN7.5', results: { min: 19.3, max: null } },
                { name: 'PN8', results: { min: 21.4, max: null } },
                { name: 'PN10', results: { min: 30, max: null } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 24.1, max: 26.7 } },
                { name: 'PN3.2', results: { min: 30, max: 33.1 } },
                { name: 'PN4', results: { min: 37.4, max: 41.3 } },
                { name: 'PN5', results: { min: 46.3, max: 51.1 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 15.4, max: 17.1 } },
                { name: 'PN3.2', results: { min: 19.3, max: 21.4 } },
                { name: 'PN4', results: { min: 24.1, max: 26.7 } },
                { name: 'PN5', results: { min: 30, max: 33.1 } },
                { name: 'PN6', results: { min: 35.7, max: 39.4 } },
                { name: 'PN8', results: { min: 46.3, max: 51.1 } },
                { name: 'PN10', results: { min: 57.2, max: 63.1 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN3.2', results: { min: 15.4, max: 17.1 } },
                { name: 'PN4', results: { min: 19.3, max: 21.4 } },
                { name: 'PN5', results: { min: 24.1, max: 26.7 } },
                { name: 'PN6', results: { min: 30, max: 33.1 } },
                { name: 'PN8', results: { min: 37.4, max: 41.3 } },
                { name: 'PN10', results: { min: 46.3, max: 51.1 } },
                { name: 'PN12.5', results: { min: 57.2, max: 63.1 } },
              ]
            }
          }]
        }
      },

      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 15.4, max: 17.1 } },
                { name: 'PN5', results: { min: 19.3, max: 21.4 } },
                { name: 'PN6', results: { min: 24.1, max: 26.7 } },
                { name: 'PN8', results: { min: 30, max: 33.1 } },
                { name: 'PN10', results: { min: 37.4, max: 41.3 } },
                { name: 'PN12.5', results: { min: 46.3, max: 51.1 } },
                { name: 'PN16', results: { min: 57.2, max: 63.1 } },
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN710',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 17.4, max: null } },
                { name: 'PN7.5', results: { min: 20.7, max: null } },
                { name: 'PN8', results: { min: 21.8, max: null } },
                { name: 'PN10', results: { min: 27.2, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PVC2',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 20.7, max: null } },
                { name: 'PN7.5', results: { min: 21.8, max: null } },
                { name: 'PN8', results: { min: 27.2, max: null } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 27.2, max: 30.1 } },
                { name: 'PN3.2', results: { min: 33.9, max: 37.4 } },
                { name: 'PN4', results: { min: 42.1, max: 46.5 } },
                { name: 'PN5', results: { min: 52.2, max: 57.6 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 17.4, max: 19.3 } },
                { name: 'PN3.2', results: { min: 21.8, max: 24.1 } },
                { name: 'PN4', results: { min: 27.2, max: 30.1 } },
                { name: 'PN5', results: { min: 33.9, max: 37.4 } },
                { name: 'PN6', results: { min: 40.2, max: 44.4 } },
                { name: 'PN8', results: { min: 52.2, max: 57.6 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN3.2', results: { min: 17.4, max: 19.3 } },
                { name: 'PN4', results: { min: 21.8, max: 24.1 } },
                { name: 'PN5', results: { min: 27.2, max: 30.1 } },
                { name: 'PN6', results: { min: 33.9, max: 37.4 } },
                { name: 'PN8', results: { min: 42.1, max: 46.5 } },
                { name: 'PN10', results: { min: 52.2, max: 57.6 } },
              ]
            }
          }]
        }
      },

      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 17.4, max: 19.3 } },
                { name: 'PN5', results: { min: 21.8, max: 24.1 } },
                { name: 'PN6', results: { min: 27.2, max: 30.1 } },
                { name: 'PN8', results: { min: 33.9, max: 37.4 } },
                { name: 'PN10', results: { min: 42.1, max: 46.5 } },
                { name: 'PN12.5', results: { min: 52.2, max: 57.6 } },
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN800',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 19.6, max: null } },
                { name: 'PN7.5', results: { min: 23.3, max: null } },
                { name: 'PN8', results: { min: 24.5, max: null } },
                { name: 'PN10', results: { min: 30.6, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PVC2',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 23.3, max: null } },
                { name: 'PN7.5', results: { min: 24.5, max: null } },
                { name: 'PN8', results: { min: 30.6, max: null } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 30.6, max: 33.8 } },
                { name: 'PN3.2', results: { min: 38.1, max: 42.1 } },
                { name: 'PN4', results: { min: 47.4, max: 52.3 } },
                { name: 'PN5', results: { min: 58.8, max: 64.8 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 19.6, max: 21.7 } },
                { name: 'PN3.2', results: { min: 24.5, max: 27.1 } },
                { name: 'PN4', results: { min: 30.6, max: 33.8 } },
                { name: 'PN5', results: { min: 38.1, max: 42.1 } },
                { name: 'PN6', results: { min: 45.3, max: 50 } },
                { name: 'PN8', results: { min: 58.8, max: 64.8 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN3.2', results: { min: 19.6, max: 21.7 } },
                { name: 'PN4', results: { min: 24.5, max: 27.1 } },
                { name: 'PN5', results: { min: 30.6, max: 33.8 } },
                { name: 'PN6', results: { min: 38.1, max: 42.1 } },
                { name: 'PN8', results: { min: 47.4, max: 52.3 } },
                { name: 'PN10', results: { min: 58.8, max: 64.8 } },
              ]
            }
          }]
        }
      },

      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 19.6, max: 21.7 } },
                { name: 'PN5', results: { min: 24.5, max: 27.1 } },
                { name: 'PN6', results: { min: 30.6, max: 33.8 } },
                { name: 'PN8', results: { min: 38.1, max: 42.1 } },
                { name: 'PN10', results: { min: 47.4, max: 52.3 } },
                { name: 'PN12.5', results: { min: 58.8, max: 64.8 } },
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN900',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 22, max: null } },
                { name: 'PN7.5', results: { min: 26.3, max: null } },
                { name: 'PN8', results: { min: 27.6, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PVC2',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 26.3, max: null } },
                { name: 'PN7.5', results: { min: 27.6, max: null } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 34.4, max: 38.3 } },
                { name: 'PN3.2', results: { min: 42.9, max: 47.3 } },
                { name: 'PN4', results: { min: 53.3, max: 58.8 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 22, max: 24.3 } },
                { name: 'PN3.2', results: { min: 27.6, max: 30.5 } },
                { name: 'PN4', results: { min: 34.4, max: 38.3 } },
                { name: 'PN5', results: { min: 42.9, max: 47.3 } },
                { name: 'PN6', results: { min: 51, max: 56.2 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN3.2', results: { min: 22, max: 24.3 } },
                { name: 'PN4', results: { min: 27.6, max: 30.5 } },
                { name: 'PN5', results: { min: 34.4, max: 38.3 } },
                { name: 'PN6', results: { min: 42.9, max: 47.3 } },
                { name: 'PN8', results: { min: 53.3, max: 58.8 } },
              ]
            }
          }]
        }
      },

      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 22, max: 24.3 } },
                { name: 'PN5', results: { min: 27.6, max: 30.5 } },
                { name: 'PN6', results: { min: 34.4, max: 38.3 } },
                { name: 'PN8', results: { min: 42.9, max: 47.3 } },
                { name: 'PN10', results: { min: 53.3, max: 58.8 } },
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN1000',
    materials: [
      {
        name: 'PVC',
        content: {
          security: [{
            value: 2,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 24.5, max: null } },
                { name: 'PN7.5', results: { min: 29.2, max: null } },
                { name: 'PN8', results: { min: 30.6, max: null } },

              ]
            }
          }]
        }
      },
      {
        name: 'PVC2',
        content: {
          security: [{
            value: 2.5,
            content:
            {
              pressure: [
                { name: 'PN6', results: { min: 29.2, max: null } },
                { name: 'PN7.5', results: { min: 30.6, max: null } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 38.2, max: 42.2 } },
                { name: 'PN3.2', results: { min: 47.7, max: 52.6 } },
                { name: 'PN4', results: { min: 59.3, max: 65.4 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 24.5, max: 27.1 } },
                { name: 'PN3.2', results: { min: 20.6, max: 33.5 } },
                { name: 'PN4', results: { min: 38.2, max: 42.2 } },
                { name: 'PN5', results: { min: 47.7, max: 52.6 } },
                { name: 'PN6', results: { min: 56.6, max: 62.4 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN3.2', results: { min: 24.5, max: 27.1 } },
                { name: 'PN4', results: { min: 20.6, max: 33.5 } },
                { name: 'PN5', results: { min: 38.2, max: 42.2 } },
                { name: 'PN6', results: { min: 47.7, max: 52.6 } },
                { name: 'PN8', results: { min: 59.3, max: 65.4 } },
              ]
            }
          }]
        }
      },

      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 24.5, max: 27.1 } },
                { name: 'PN5', results: { min: 20.6, max: 33.5 } },
                { name: 'PN6', results: { min: 38.2, max: 42.2 } },
                { name: 'PN8', results: { min: 47.7, max: 52.6 } },
                { name: 'PN10', results: { min: 59.3, max: 65.4 } },
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN1200',
    materials: [
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 45.9, max: 50.6 } },
                { name: 'PN3.2', results: { min: 57.2, max: 63.1 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 29.4, max: 32.5 } },
                { name: 'PN3.2', results: { min: 36.7, max: 40.5 } },
                { name: 'PN4', results: { min: 45.9, max: 50.6 } },
                { name: 'PN5', results: { min: 57.2, max: 63.1 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN3.2', results: { min: 29.4, max: 32.5 } },
                { name: 'PN4', results: { min: 36.7, max: 40.5 } },
                { name: 'PN5', results: { min: 45.9, max: 50.6 } },
                { name: 'PN6', results: { min: 57.2, max: 63.1 } },
              ]
            }
          }]
        }
      },

      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 29.4, max: 32.5 } },
                { name: 'PN5', results: { min: 36.7, max: 40.5 } },
                { name: 'PN6', results: { min: 45.9, max: 50.6 } },
                { name: 'PN8', results: { min: 57.2, max: 63.1 } },
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN1400',
    materials: [
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 53.5, max: 59 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 34.3, max: 37.9 } },
                { name: 'PN3.2', results: { min: 42.9, max: 47.3 } },
                { name: 'PN4', results: { min: 53.5, max: 59 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN3.2', results: { min: 34.3, max: 37.9 } },
                { name: 'PN4', results: { min: 42.9, max: 47.3 } },
                { name: 'PN5', results: { min: 53.5, max: 59 } },
              ]
            }
          }]
        }
      },

      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 34.3, max: 37.9 } },
                { name: 'PN5', results: { min: 42.9, max: 47.3 } },
                { name: 'PN6', results: { min: 53.5, max: 59 } },
              ]
            }
          }]
        }
      },
    ]
  },
  {
    diameter: 'DN1600',
    materials: [
      {
        name: 'PE40',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 61.2, max: 67.5 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE63',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN2.5', results: { min: 39.2, max: 43.3 } },
                { name: 'PN3.2', results: { min: 49, max: 54 } },
                { name: 'PN4', results: { min: 61.2, max: 67.5 } },
              ]
            }
          }]
        }
      },
      {
        name: 'PE80',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN3.2', results: { min: 39.2, max: 43.3 } },
                { name: 'PN4', results: { min: 49, max: 54 } },
                { name: 'PN5', results: { min: 61.2, max: 67.5 } },
              ]
            }
          }]
        }
      },

      {
        name: 'PE100',
        content: {
          security: [{
            value: 1.25,
            content:
            {
              pressure: [
                { name: 'PN4', results: { min: 39.2, max: 43.3 } },
                { name: 'PN5', results: { min: 49, max: 54 } },
                { name: 'PN6', results: { min: 61.2, max: 67.5 } },
              ]
            }
          }]
        }
      },
    ]
  },
]