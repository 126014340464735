export const userTypes = {
    ADD_CONTACT_DETAILS: 'user/ADD_CONTACT_DETAILS',
  }
  
  export const addContactDetails = (payload) => ({
    type: userTypes.ADD_CONTACT_DETAILS,
    payload
  })
  

  