import React, { Component } from 'react'
import { connect } from 'react-redux'
import ContactsDesktop from './ContactsDesktop'
import ContactsMobile from './ContactsMobile'
import { withGetScreen } from 'react-getscreen'

class Contacts extends Component {
  render() {
    if (this.props.isMobile()) {
      return <ContactsMobile />;
    } else {
      return <ContactsDesktop />;
    }
  }
}

const options = { mobileLimit: 900, shouldListenOnResize: true }
export default connect()(withGetScreen(Contacts, options))