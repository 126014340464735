import React, { Component } from 'react'
import { connect } from 'react-redux'
import SolutionDesktop from './SolutionDesktop'
import SolutionMobile from './SolutionMobile'
import { withGetScreen } from 'react-getscreen'

class Solution extends Component {
  render() {
    if (this.props.isMobile()) {
      return <SolutionMobile />
    } else {
      return <SolutionDesktop />
    }
  }
}

const options = { mobileLimit: 900, shouldListenOnResize: true }
export default connect()(withGetScreen(Solution, options))
