import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Socials } from '../../components/Socials'

import './styles.scss'

class NavSide extends Component {
  render() {
    return (
      <div className='nav-side'>
        <Socials />
      </div>
    );
  }
}

export default connect()(NavSide);
